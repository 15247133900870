<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <!-- 연계 인명부 등록에서는 tooltip 삭제 -->
      <!-- <v-tooltip
        slot="tooltip-area"
        bottom class="ml-3"
        color="primary"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            small
            style="cursor:pointer;"
          >mdi-help-circle</v-icon>
        </template>
        <span class="text-body-2">일괄신규등록은 [관리자>정보일괄관리]에서 가능합니다.</span>
      </v-tooltip> -->

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >mdi-star-outline</v-icon>
        <v-icon
          v-else
          color="yellow"
        >mdi-star</v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-0 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-form ref="form">
            <v-row no-gutters>
              <v-col cols="12" xs="12" class="mt-0">
                <v-select
                  v-model="form.teamId"
                  :items="teams"
                  item-text="text"
                  item-value="value"
                  label="담당부서"
                  prepend-icon="mdi-lock"
                  readonly
                  background-color="grey lighten-3"
                  class="mt-1"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" class="mt-2">
                <span class="text-h6 mt-0 mb-2 pb-0 green--text">기본정보</span>
              </v-col>
              <v-col cols="12" xs="12" sm="4" class="pr-2">
                <v-select
                  v-model="form.gubun1"
                  :items="cltypes"
                  item-text="text"
                  item-value="value"
                  label="주체유형"
                  menu-props="auto"
                  class="mt-1"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="8">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <div style="width:240px">
                    <v-text-field
                      ref="txtClientName"
                      v-model.trim="form.name"
                      v-validate="'required|max:30'"
                      maxlength="30"
                      :error-messages="errors.collect('name')"
                      data-vv-name="name"
                      :counter="30"
                      required
                      label="*이름"
                      placeholder="예)홍길동"
                      hint="중복체크 필수"
                      @keyup="chkClientNameOverlapReset"
                      :error="isOverlabed"
                    ></v-text-field>
                  </div>
                  <v-btn small text class="ma-2"
                    :color="mustChk ? 'error' : 'indigo'"
                    @click="chkClientNameOverlap"
                  >
                    <v-icon small class="text-left">
                      {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    중복체크
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12">
                <v-alert type="info" dense text class="text-body-2">
                  등록된 주체는 변경될 수 없습니다.<br>
                  주체유형의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.
                </v-alert>
              </v-col>
              <v-col cols="12" xs="12" sm="4" class="pr-2">
                <v-select
                  v-model="form.gubun2"
                  :items="selGubun2"
                  item-text="text"
                  item-value="value"
                  label="주체구분"
                  menu-props="auto"
                  class="mt-1"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="8">
                <v-text-field
                  v-model.trim="form.national"
                  label="국적"
                  maxlength="30"
                  counter="30"
                  hint="예)대한민국"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12">
                <v-text-field
                  v-model.trim="form.address"
                  label="주소"
                  maxlength="50"
                  counter="50"
                  placeholder="입력하세요"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" class="pr-2">
                <v-text-field
                  v-model.trim="form.hp"
                  label="휴대전화"
                  maxlength="30"
                  counter="30"
                  hint="010-1234-5678"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  v-model.trim="form.tel"
                  label="일반전화"
                  maxlength="30"
                  counter="30"
                  hint="02-1234-5678"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" class="pr-2">
                <v-text-field
                  v-model.trim="form.fax"
                  label="팩스"
                  maxlength="30"
                  counter="30"
                  hint="02-1234-5678"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6">
                <v-text-field
                  v-model.trim="form.email"
                  label="이메일"
                  maxlength="50"
                  counter="50"
                  hint="lawork@gmail.com"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" class="mb-5">
                <!-- <v-alert type="info" dense text class="text-caption">
                  주체유형의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.
                </v-alert> -->
              </v-col>
              <v-col cols="12" xs="12" class="mt-0">
                <v-row
                  align="center"
                  justify="start"
                  no-gutters
                >
                  <span class="text-h6 mt-2 mb-0 pb-0 green--text">관리개요</span>
                  <v-tooltip bottom color="primary">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small class="mt-1 ml-3" color="primary" style="cursor:pointer;">mdi-help-circle</v-icon>
                    </template>
                    <span class="text-body-2">관리그룹의 추가,변경,삭제와 순서변경은 [관리자>카테고리관리]에서 가능합니다.</span>
                  </v-tooltip>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" class="mt-0">
                <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">관리그룹</span>
              </v-col>
              <v-col cols="12"
                v-for="(group, i) in mGroups"
                :key="i"
              >
                <v-toolbar dense flat>
                  <v-btn text class="px-1">
                    {{ mGroups[i].title }}
                  </v-btn>
                  <div class="mx-5 mt-5 mb-2" style="width:300px">
                    <v-select
                      v-model="form[`mgroup${i+1}`]"
                      :items="mGroups[i].selects"
                      dense
                      menu-props="auto"
                      style="font-size: 0.785rem !important"
                    ></v-select>
                  </div>
                  <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                    <v-icon
                      small
                      @click="addMGroup(group, i)"
                      class="pb-1"
                    >mdi-plus-box-outline</v-icon>
                  </v-btn>
                </v-toolbar>
              </v-col>
              <v-col cols="12" xs="12">
                <div class="mb-8"></div>
              </v-col>

            </v-row>

          </v-form>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// !![2021.5.6]lwc vuex 모듈 불러오기
import { createNamespacedHelpers } from 'vuex'

// !! lwc vuex 모듈
const _Lwc_ = createNamespacedHelpers('lwc')

export default {
  components: {
    baseModal
  },

  props: [],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 1, // 인명부 주체유형 초기값(의뢰인)
      gubun1: '의뢰인', // 유형명 초기값(의뢰인)
      gubun2: '개인', // 주체구분
      mgroup1: '', // 관리그룹 1
      mgroup2: '', // 관리그룹 2
      name: '', // 인명부상의 이름
      national: '', // 국적
      isStar: 0, // 중요표시 : 별
      address: '',
      hp: '',
      tel: '',
      fax: '',
      email: ''
    },
    // 구분:
    teams: [], // 소속팀
    cltypes: [], // 주체유형(의뢰인/상대방/기타)
    selGubun2: [ // 주체구분
      '개인', '기업', '단체', '관공서'
    ],
    // 구분: 관리그룹 - 차후 5개까지 확장가능
    mGroups: [
      {
        id: 0,
        title: '',
        value: '',
        selects: [
          { text: '선택', value: '' }
        ]
      },
      {
        id: 0,
        title: '',
        value: '',
        selects: [
          { text: '선택', value: '' }
        ]
      }
    ],
    // 구분: 주의: 이 페이지는 소송/자문의 연계의뢰인을 등록하므로 수정은 없고 등록만 한다.
    // index: -1, // !! 수정 인덱스
    viewWname: '', // 처리자를 보여주기만 하는 변수
    viewUpdatedAt: '', // 업데이트 일자를 보여주기 위한 변수
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        name: '이름'
      }
    },
    // 구분: 중복체크
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 이름 중복 확인했는지 여부
    isOverlabed: false // 참고: 이름 중복 되었는지 여부
  }),

  watch: {
    'form.name': { // !! 이름 중복체크를 하도록 하는 로직의 핵심임
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          // if (isSpKey(val)) { // 특수문자 필터링 - 필터
          // !! 원본 : /[\s{}[\]/?.,;:|)*~`!^+<>@#$%&\\=('"]/gi
          // 중요: 공백,()은 허용하기 위해 \s,() 를 뺌
          // (주)가나제과, 이사 김상백
          if (/[{}[\]/?.,;:|*~`!^+<>@#$%&\\='"]/gi.test(val)) {
            this.form.name = oldVal
            this.$refs.txtClientName.lazyValue = oldVal
          }

          this.mustChk = true // 중복체크 해야 함
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabed = false // 중복 아니다.
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  computed: {
    // 중요: lwc 모듈 게터
    ..._Lwc_.mapGetters([
      'isMyTeam',
      'getTeamNameByTid'
    ])
  },

  methods: {
    // isSpKey, // 특수문자 필터링
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 카테고리에서 관리그룹 패칭
    async getGroups () {
      try {
        // 카테고리 : 관리그룹 = 1
        this.setCates('1').then(({ cates }) => {
          if (cates && cates.length > 0) {
            for (let i = 0; i < cates.length; i++) {
              this.mGroups[i].id = cates[i].id
              this.mGroups[i].title = cates[i].gubun1

              // select 초기화 하고 다시 매칭
              this.mGroups[i].selects = [{ text: '선택', value: '' }]
              const subs = cates[i].sub.split('|')
              subs.forEach(sub => {
                this.mGroups[i].selects.push({ text: sub, value: sub })
              })
            }
          }
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // form 초기화
      // this.$refs.form.reset() // !! 이넘이 시간이 오래 걸려서 밑에 폼 초기화가 제대로 안된다!

      // 대신 form validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.type = 1 // type을 1(의뢰인으로)
      this.form.gubun1 = '의뢰인' // 주체유형을 카테고리에서 가져오면 의뢰인을 기본값으로 매칭한다
      this.form.gubun2 = '개인' // 주체구분
      this.form.mgroup1 = '' // 관리그룹 1
      this.form.mgroup2 = '' // 관리그룹 1
      this.form.name = ''
      this.form.national = ''
      this.form.isStar = 0
      this.form.address = ''
      this.form.hp = ''
      this.form.tel = ''
      this.form.fax = ''
      this.form.email = ''

      // this.index = -1 // 수정용 인덱스 초기화 주의: 소송/자문 에서의 새 의뢰인 등록이므로 수정은 없다.

      // 중복체크
      this.mustChk = false
      this.chkMulti = false
      this.isOverlabed = false
    },
    // 주의: 소송/자문 에서의 새로운 의뢰인 등록이므로 수정은 없다.
    // !! teamInfo(팀 정보객체), clTypeIfno(주체유형 객체) 을 넘겨받아서 연다!
    // !! 팀선택과 주체유형 선택은 넘겨받은 정보로 고정시켜야 한다!
    async open (title, options, teamInfo, clTypeIfno) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 관리그룹 - 카테고리에서 가져온다
        await this.getGroups()

        // 초기화
        await this.initDialog()

        // 중요: 넘겨받은 팀정보로 this.teams 를 매칭한다!
        this.teams = [ teamInfo ]
        this.form.teamId = teamInfo.value

        // 중요: 넘겨받은 주체유형 정보로 this.from.type 과 this.cltypes 를 만든다
        this.cltypes = [ clTypeIfno.typeName ]
        this.form.type = clTypeIfno.type // 주체유형(의뢰인/상대방) 타입번호 매칭
        this.form.gubun1 = clTypeIfno.typeName // 주체유형명 매칭

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        // if (!isValidate) throw new Error('입력값을 확인해 주세요')
        if (!isValidate) throw new Error(`입력값을 확인해 주세요. - ${this.errors.items[0].msg}`)

        // !! 이름 중복체크 확인
        if (this.mustChk && !this.chkMulti) { // 중복체크를 해야 하는데 하지 않았다
          this.isOverlabed = true // 중복여부를 가져오진 않았지만 수동으로 에러를 만든다.
          this.$refs.txtClientName.focus()
          throw new Error('중복체크를 하셔야 합니다.')
        }

        let affectedId = 0
        // 주의: 소송/자문에서의 새 인명부 등록이므로 수정은 없다.
        // !! 인명부 등록을 그대로 사용한다!
        const { data } = await this.$axios.post(`lawork/client/addClient`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        affectedId = data.insertId
        await this.initDialog()
        this.resolve(affectedId) // !! 정상적으로 등록이 되면 App.vue clientAdd() 함수로 등록된 아이디를 보낸다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        // 초기화
        await this.initDialog()
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 이름 중복체크 함수
    // !! 이름 && 팀 모두 동일해야 중복으로 인정된다!
    // 중복되어도 체크만 하면 등록가능하다! 동명이인이 있을 수 있으므로
    async chkClientNameOverlap () {
      try {
        // !![2021.5.31]담당부서 체크는 뺐다.
        // let isValidate = await this.$validator.validateAll()
        // if (!isValidate && !this.form.teamId) throw new Error('담당부서를 선택하셔야 합니다.')

        if (this.form.name.length > 0) {
          const { data } = await this.$axios.get(`lawork/client/chkClientNameOverlap/${this.form.teamId}/${this.form.name}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) { // 중복 되었다
            // !![2021.5.6] 중복처리 추가
            const { tIds } = data // 넘어온 팀아이디 배열
            let chkMsg = ''
            if (tIds.length > 1) { // 복수라면 첫번째 팀만 보이고 '~외' 처리
              chkMsg = `${this.getTeamNameByTid(tIds[0])}외 ${tIds.length - 1}`
            } else {
              chkMsg = `${this.getTeamNameByTid(tIds[0])}`
            }

            this.mustChk = false // 중복되어도 등록할 수 있으니 풀어준다
            this.chkMulti = true // 역시 중복이어도 체크한걸로 넘어간다
            this.isOverlabed = false // 에러체크도 풀어준다.
            this.$refs.txtClientName.focus()

            // throw new Error(`이미 등록된 이름입니다(${msg}).동명이인이라면 등록을 계속하십시오.`)
            const _msg_ = `이미 등록된 이름입니다(${chkMsg}).동명이인이라면 등록을 계속하십시오.`
            this.$store.commit('SB_POP', { msg: _msg_, color: 'warning', shaped: true })
          } else {
            this.mustChk = false
            this.chkMulti = true
            this.isOverlabed = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 이름입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 이름 텍스트박스의 keyup 이벤트 - 중복체크 리셋
    async chkClientNameOverlapReset (e) {
      // 체크했는지 여부와 중복여부를 리셋시킨다.
      this.chkMulti = false
      this.isOverlabed = false
    },
    // !! [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 그룹추가 팝업 열기
    async addMGroup (group, index) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'addMgroupDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 부모 그룹 타이틀과 아이디를 넘긴다
        const returnValue = await pop.open(group.title, group.id)
        // 정상적으로 등록이 되었으면 - 그넘으로 선택한다.
        if (returnValue) {
          await this.getGroups() // 셀렉트 리프레시 하고
          this.form[`mgroup${index + 1}`] = returnValue // 선택
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
