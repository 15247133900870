<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        v-if="!isAutoInput"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1 && !isAutoInput"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <!-- <v-checkbox
        v-if="index > -1"
        slot="func-option"
        v-model="addLimitDay"
        dense
        label="기한추가"
        class="mt-3"
      ></v-checkbox> -->

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str1"
                v-validate="'required|max:20'"
                :error-messages="errors.collect('str1')"
                data-vv-name="str1"
                maxlength="20"
                counter="20"
                required
                label="종국결과*"
                hint="예)원고일부승"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str2"
                maxlength="50"
                counter="50"
                label="상세결과"
                placeholder="예) 2,500만원 중 1,000만원 지급"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-select
                v-model="form.str3"
                :items="str3Select"
                item-text="text"
                item-value="value"
                label="결과평가"
                dense
                menu-props="auto"
                class="mt-4 mb-2"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="2">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="mt-6 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle</v-icon>
                </template>
                <span class="text-body-2">결과평가의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-select
                v-model="form.str4"
                :items="str4Select"
                item-text="text"
                item-value="value"
                label="향후계획"
                dense
                menu-props="auto"
                class="mt-4 mb-2"
              ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="2">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" small class="mt-6 ml-2" color="primary" style="cursor:pointer;">mdi-help-circle</v-icon>
                </template>
                <span class="text-body-2">향후계획의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ article.manageNum }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 21, // 중요: 유형 - 진행내용
      depth: 2,
      pId: 0,
      pType: 2, // 중요: 부모타입 = 자문
      pInfo: '', // 정보위치(부모의 정보 중 보여줄 정보)
      isStar: 0,
      isSchedule: 1,
      subtype: 8, // 중요: 기본값은 종국 = 8
      gubun1: '', // 타이틀 = 종국
      status: 1,
      date1: '', // 일자 - 종국일(endDate)
      str1: '', // 종국결과(endType)
      str2: '', // 상세결과(endMemo)
      str3: '', // 결과평가(endResult)
      str4: '', // 향후계획(appealPlan)
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // !!구분: 팝업을 열면서 넘어온 subtype 을 매칭시켜야 한다!
    subType: 8,
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str1: '위 '
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: 일자 - 시간
    date1Selector: false, // 일자 셀렉터
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 자동입력 데이터인 경우
    isAutoInput: false,
    // 구분: 결과평가, 향후계획 셀렉트 배열
    str3Select: [ // 결과평가
      { text: '선택', value: '' }
    ],
    str4Select: [ // 향후계획
      { text: '선택', value: '' }
    ],
    // 구분: 기한추가 체크박스
    addLimitDay: false
  }),

  watch: {
    'form.str2': {
      handler: function (val, oldVal) {
        if (val.length > 0) {
          if (val.length > 1 && val[0] === '0') {
            this.form.str2 = val.substr(1)
          } else {
            // 1) 숫자중 쉼표를 없애고
            this.form.str2 = numberFormatR(this.form.str2)
            // 2) 숫자3자리 마다 쉼표를 찍는다.
            this.form.str2 = numberFormat(this.form.str2)
          }
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    strDateFormat,
    numberFormat,
    numberFormatR,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.subtype = this.subType // 중요: 넘어온 subtype 을 매칭한다!
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 2 // 중요: 부모 타입 = 자문
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보)
      this.form.gubun1 = this.title // !! 넘어온 상단의 제목이 gubun1 이 된다!
      this.form.status = 1
      this.form.isStar = 0
      this.form.isSchedule = 1 // 일정표시여부
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 일시 초기값(오늘)
      this.form.str1 = '' // 종국결과
      this.form.str2 = '' // 상세결과
      this.form.str3 = '' // 결과평가
      this.form.str4 = '' // 향후계획
      this.form.parentInfo = ''

      this.isAutoInput = false
      this.addLimitDay = false // 기한추가 체크박스 초기화

      this.index = -1 // 수정용 인덱스 초기화
    },
    async open (title, options, subtype, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 종국 > 결과평가 카테고리 패칭
        this.setCates('5').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.str3Select.push(...cates.map(c => ({ text: c.gubun1, value: c.gubun1 })))
          }
        })

        // 종국 > 향후계획 카테고리 패칭
        this.setCates('6').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.str4Select.push(...cates.map(c => ({ text: c.gubun1, value: c.gubun1 })))
          }
        })

        // 중요: 파라미터로 넘어온 subtype 에 따라 기일/기한이 결정되므로 중요하다
        this.subType = subtype

        // console.log(this.article) // !! props 로 넘어온 부모 데이터

        // 초기화
        await this.initDialog()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id

        this.form.parentInfo = this.article.manageNum

        // 중요:[2021.3.22] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo)

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1 // 진행내용 타입 문자열 - 종국
          this.form.status = obj.status
          this.form.subtype = obj.subtype // !! 이미 초기화에서 적용했으므로 의미는 없는데?
          this.form.isStar = obj.isStar

          // !! 진행내용에선 의미 없지만 일단 놔둔다.
          // 등록과 같은 자동입력 데이터는 유형을 재조정 - 유형재조정은 포기
          if (obj.subtype === 0) {
            this.isAutoInput = true // 자동입력 데이터임을 표시
          }

          this.form.str1 = obj.str1 // 종국결과
          this.form.str2 = obj.str2 // 상세결과
          this.form.str3 = obj.str3 // 결과평가
          this.form.str4 = obj.str4 // 향후계획

          // 일자-시간 처리
          this.form.date1 = obj.date1.substr(0, 10)

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록/수정
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // !! 종국일시 문자열 만들기 - 년월일 + 현재 시분초
        // this.form.date1 = `${this.form.date1.substr(0, 10)} ${this.$moment().format('HH:mm:ss')}`
        // !!중요:[2021.4.8] 종국일시 수정 - 00:10:01 로 변경
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:10:01`

        // !! 돌려주는 객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러가 있는 경우 채워지는 객체
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/advice/setUpmuContent`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = this.addLimitDay ? 'addLimitDay' : 'edited' // !! 기한추가인 경우 addLimitDay 로 리턴을 보낸다

        // 기한추가인 경우 addLimitDay 로 리턴을 보낸다
        // const returnResult = this.addLimitDay ? 'addLimitDay' : 'success'

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // !! this.resolve(returnResult)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! 돌려주는 객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/advice/deleteUpmuContent`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed' // 삭제됨

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // !! 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
        this.$router.push(`/advice/${this.article.id}`)
      }
    },
    // !! [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
