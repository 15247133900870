<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row no-gutters>
      <v-col cols="12" sm="3">
        <div>
          <v-icon></v-icon>
        </div>
        <div class="pt-0 pb-2 px-1">
          <v-text-field
            ref="refAmt1"
            v-model="amt1"
            maxlength="12"
            @keypress="preventComma"
            dense
            hide-details
            filled
            rounded
            @blur="blurAmt1"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div>
          <v-icon></v-icon>
        </div>
        <div class="pt-0 pb-2 px-1">
          <v-text-field
            ref="refAmt2"
            v-model="amt2"
            maxlength="12"
            @keypress="preventComma"
            dense
            hide-details
            filled
            rounded
            @blur="blurAmt2"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small class="mr-3" color="success"
                @click.stop="viewTimeSheetPop"
              >mdi-cloud-refresh</v-icon>
            </template>
            <span>불러오기</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small class="mr-2" color="indigo"
                @click.stop="pdfgenTimeSheet"
              >mdi-file-pdf</v-icon>
            </template>
            <span>pdf</span>
          </v-tooltip>
        </div>
        <div class="pt-0 pb-2 px-1">
          <v-text-field
            ref="refAmt3"
            v-model="amt3"
            maxlength="12"
            @keypress="preventComma"
            dense
            hide-details
            filled
            rounded
            @blur="blurAmt3"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small class="mr-3" color="success"
                @click.stop="viewCostPop"
              >mdi-cloud-refresh</v-icon>
            </template>
            <span>불러오기</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                small class="mr-2" color="indigo"
                @click.stop="pdfgenCost"
              >mdi-file-pdf</v-icon>
            </template>
            <span>pdf</span>
          </v-tooltip>
        </div>
        <div class="pt-0 pb-2 px-1">
          <v-text-field
            ref="refAmt4"
            v-model="amt4"
            maxlength="12"
            @keypress="preventComma"
            dense
            hide-details
            filled
            rounded
            @blur="blurAmt4"
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// @: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber'
import strDateFormat from '@/filters/strDateFormat'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

export default {
  components: {
  },

  props: [
    'amts',
    'timeSheets',
    'costs',
    'timeSheetSum',
    'timeMinutes',
    'costSum',
    'pdfTitle'
  ],

  data: () => ({
    amt1: '0',
    amt2: '0',
    amt3: '0',
    amt4: '0',
    // 구분: pdf 리스트
    pdfLst: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    }
  }),

  created () {
    this.amt1 = this.amts.amt1
    this.amt2 = this.amts.amt2
    this.amt3 = this.amts.amt3
    this.amt4 = this.amts.amt4
  },

  watch: {
    amt1: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt1 = '0'
          this.$refs.refAmt1.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt1 = val.replace(/(^0+)/, '')
          }
          this.amt1 = numberFormatR(this.amt1)
          this.amt1 = numberFormat(this.amt1)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt1)) {
            this.amt1 = oldVal
            this.$refs.refAmt1.lazyValue = oldVal
          }
        }
      }
    },
    amt2: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt2 = '0'
          this.$refs.refAmt2.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt2 = val.replace(/(^0+)/, '')
          }
          this.amt2 = numberFormatR(this.amt2)
          this.amt2 = numberFormat(this.amt2)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt2)) {
            this.amt2 = oldVal
            this.$refs.refAmt2.lazyValue = oldVal
          }
        }
      }
    },
    amt3: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt3 = '0'
          this.$refs.refAmt3.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt3 = val.replace(/(^0+)/, '')
          }
          this.amt3 = numberFormatR(this.amt3)
          this.amt3 = numberFormat(this.amt3)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt3)) {
            this.amt3 = oldVal
            this.$refs.refAmt3.lazyValue = oldVal
          }
        }
      }
    },
    amt4: {
      handler: function (val, oldVal) {
        if (!val) {
          this.amt4 = '0'
          this.$refs.refAmt4.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.amt4 = val.replace(/(^0+)/, '')
          }
          this.amt4 = numberFormatR(this.amt4)
          this.amt4 = numberFormat(this.amt4)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.amt4)) {
            this.amt4 = oldVal
            this.$refs.refAmt4.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    strDateFormat,
    numberFormat,
    numberFormatR,
    preventComma,
    strToNumber,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 부모의 값까지 같이 변경시키도록 한다.
    blurAmt1 () {
      this.amts.amt1 = strToNumber(this.amt1)
    },
    blurAmt2 () {
      this.amts.amt2 = strToNumber(this.amt2)
    },
    blurAmt3 () {
      this.amts.amt3 = strToNumber(this.amt3)
    },
    blurAmt4 () {
      this.amts.amt4 = strToNumber(this.amt4)
    },
    // 구분: 시간보수 합계 적용 팝업
    async viewTimeSheetPop () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `대상 합계: ${numberFormat(this.timeSheetSum)}원<br>적용하시겠습니까?`
        if (await pop.open('시간보수', msg, { color: 'success', width: 400 })) {
          this.amt3 = this.timeSheetSum + ''
          this.amts.amt3 = this.timeSheetSum
          this.$nextTick(() => {
            this.$refs.refAmt3.focus()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 비용 합계 적용 팝업
    async viewCostPop () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `대상 합계: ${numberFormat(this.costSum)}원<br>적용하시겠습니까?`
        if (await pop.open('비용청구', msg, { color: 'success', width: 400 })) {
          this.amt4 = this.costSum + ''
          this.amts.amt4 = this.costSum
          this.$nextTick(() => {
            this.$refs.refAmt4.focus()
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터 - 타임시트
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgenTimeSheet () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `시간보수 > ${this.pdfTitle}`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '업무자', style: 'tableHeader', alignment: 'center' },
          { text: '시간당보수', style: 'tableHeader', alignment: 'center' },
          { text: '업무시간', style: 'tableHeader', alignment: 'center' },
          { text: '시간보수', style: 'tableHeader', alignment: 'center' }
        ])

        this.timeSheets.forEach(d => {
          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          tBody.push([
            `${strDateFormat(d.date1)}`,
            `${d.str3}`,
            `${numberFormat(d.str4)}`,
            `${d.str5}`,
            `${numberFormat(d.str2)}`
          ])
        })
        // 중요:!! colSpan 을 해주더라도 원래 컬럼갯수만큼 빈칸을 넣어줘야 한다
        tBody.push([{ colSpan: 3, text: '합계', alignment: 'center' }, '', '', `${this.timeMinutes}`, `${numberFormat(this.timeSheetSum)}`])

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터 - 비용
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgenCost () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = `비용청구 > ${this.pdfTitle}`

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfLst.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfLst.styles = pdfListStyle
        this.pdfLst.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '항목', style: 'tableHeader', alignment: 'center' },
          { text: '납부처', style: 'tableHeader', alignment: 'center' },
          { text: '금액', style: 'tableHeader', alignment: 'center' }
        ])

        this.costs.forEach(d => {
          // 주의: 아래 push 에 넣는 배열항목을 제거하거나 늘린 후 아래 content 의 table.widths 배열도 맞춰야 함
          tBody.push([
            `${strDateFormat(d.date1)}`,
            `${d.gubun1}`,
            `${d.str3}`,
            `${numberFormat(d.str2)}`
          ])
        })
        // 중요:!! colSpan 을 해주더라도 원래 컬럼갯수만큼 빈칸을 넣어줘야 한다
        tBody.push([{ colSpan: 3, text: '합계', alignment: 'center' }, '', '', `${numberFormat(this.costSum)}`])

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: ['*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfLst.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfLst).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
