<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="btnConfirmClick"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="btnConfirmClick">연계하기</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height"
        :max-height="options.height + 150"
      >
        <v-card-text class="ma-0 pa-0">

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <!-- 다른 팝업과 다르게 class="ma-0 pa-0" 으로 맞춰야 한다 -->
      <!-- <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0"> -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-format-list-bulleted-square</v-icon>
                      {{ subTitle2 }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small color="primary" depressed
                      @click="relClientAdd"
                    >
                      신규등록
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <div style="width: 100px;">
                        <v-select
                          v-model="searchForm2.sField"
                          :items="clTypes"
                          item-value="value"
                          item-text="text"
                          label=""
                          style="font-size: 0.785rem !important"
                          class="mt-1 ml-2"
                          hide-details
                        ></v-select>
                      </div>
                    </v-toolbar-title>
                    <v-text-field
                      v-model="searchForm2.clName"
                      label=""
                      maxlength="20"
                      clearble
                      class="mx-2"
                      @keypress.enter="getClients"
                      hide-details
                    ></v-text-field>
                    <v-btn small outlined text color="primary"
                      class="ma-0"
                      @click="getClients"
                    >
                      <v-icon small left>
                        mdi-magnify
                      </v-icon>
                      검색
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    min-height="180"
                    max-height="180"
                    class="overflow-auto text-body-2 pa-1 ma-2"
                  >
                    <v-alert v-show="clients.length <= 0"
                      text color="blue-grey" class="text-body-2"
                    >
                      해당 정보가 없습니다.(먼저 검색해 주시기 바랍니다.)
                    </v-alert>
                    <v-row
                      v-for="(item, i) in clients"
                      :key="i"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-chip
                          :disabled="item.isConnected"
                          :color="item.selected ? 'amber lighten-3' : 'white'"
                          class="ma-0"
                          @click="selectPerson2(item)"
                        >
                          <v-avatar left>
                            <v-icon small dark :color="item.isGomun ? 'purple' : 'primary'">
                              {{ item.isGomun ? 'mdi-shield-account' : 'mdi-account-circle' }}
                            </v-icon>
                          </v-avatar>
                          <span class="font-weight-bold text-body-2 mr-2">{{ item.name }}</span>
                          <span class="text-body-2">{{ item.hp ? item.hp : item.tel ? item.tel : '' }}</span>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                  </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    tile
                    elevation="0"
                    min-height="280"
                    class="pa-2"
                  >
                    <v-row
                      no-gutters
                      align="center"
                      justfy="center"
                    >
                      <v-col cols="12" xs="12">
                        <v-card
                          outlined
                          elevation="0"
                          class="pa-0"
                        >
                          <v-card-text class="text-body-2 text--primary pa-2">
                            <v-card-subtitle class="px-1 py-1">
                              <v-icon small left>mdi-account</v-icon>기본정보
                            </v-card-subtitle>
                            <v-icon small left
                              v-show="cInfo.isStar"
                              color="amber darken-1"
                              class="mb-1 ml-2"
                            >mdi-star</v-icon>
                            <span
                              v-show="cInfo.name"
                              :class="!cInfo.isStar ? 'ml-2' : ''"
                              class="text-subtitle-1 font-weight-bold blue--text text--darken-2"
                            >{{ cInfo.name }}</span>
                            <v-icon small
                              v-show="cInfo.id"
                              color="primary"
                              @click="goClientDetail(cInfo.id)"
                              class="mb-1 mx-1"
                            >mdi-launch</v-icon>
                          <div
                            v-show="cInfo.mgroupStr || cInfo.teamName"
                            class="my-0 py-0"
                          >
                            <span class="mx-2">{{ cInfo.mgroupStr }}</span>
                            <span :class="`${article.teamColor}`">
                              {{ cInfo.teamName ? `[${cInfo.teamName}]` : '' }}
                            </span>
                          </div>
                            <div
                              v-show="cInfo.national || cInfo.address"
                              class="pl-2 pb-1"
                            >
                              {{ cInfo.national ? `(${cInfo.national}) ` : '' }}{{ cInfo.address ? cInfo.address : '' }}
                            </div>
                            <div
                              v-show="cInfo.hp || cInfo.tel"
                              class="pl-2 pb-1"
                            >
                              <span v-show="cInfo.hp" class="mr-1 grey--text">M</span>
                              <span v-show="cInfo.hp" class="mr-5">{{ cInfo.hp }}</span>
                              <span v-show="cInfo.tel" class="mr-1 text-body-2 grey--text">T</span>
                              <span v-show="cInfo.tel" class="text-body-2">{{ cInfo.tel }}</span>
                            </div>
                            <div
                              v-show="cInfo.fax || cInfo.email"
                              class="pl-2 pb-1"
                            >
                              <span v-show="cInfo.fax" class="mr-1 grey--text">F</span>
                              <span v-show="cInfo.fax" class="mr-5">{{ cInfo.fax }}</span>
                              <span v-show="cInfo.email" class="mr-1 text-body-2 grey--text">E</span>
                              <span v-show="cInfo.email" class="text-body-2">{{ cInfo.email }}</span>
                            </div>
                          </v-card-text>
                          <v-divider></v-divider>
                          <v-card-text class="text-body-2 text--primary pa-2">
                            <v-card-subtitle class="px-1 py-1">
                              <v-icon small left>mdi-account-supervisor</v-icon>관계인
                            </v-card-subtitle>
                            <template v-for="(item, i) in cInfo.relPersons">
                              <v-row
                                :key="i"
                                no-gutters
                              >
                                <v-col cols="12" xs="12" class="mb-3">
                                  <div class="py-1">
                                    <span class="font-weight-bold mr-2">{{ item.str1 }}</span>
                                    <span class="font-weight-regular">{{ item.str2 ? `[${item.str2}]` : '' }}</span>
                                  </div>
                                  <div>
                                    <span v-show="item.str3" class="mr-1 grey--text">M</span>
                                    <span v-show="item.str3" class="mr-5">{{ item.str3 }}</span>
                                    <span v-show="item.str4" class="mr-1 text-body-2 grey--text">T</span>
                                    <span v-show="item.str4" class="text-body-2">{{ item.str4 }}</span>
                                  </div>
                                  <div>
                                    <span v-show="item.str5" class="mr-1 grey--text">F</span>
                                    <span v-show="item.str5" class="mr-5">{{ item.str5 }}</span>
                                    <span v-show="item.str6" class="mr-1 text-body-2 grey--text">E</span>
                                    <span v-show="item.str6" class="text-body-2">{{ item.str6 }}</span>
                                  </div>
                                </v-col>
                              </v-row>
                            </template>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        <!-- </v-container>
      </v-card-text> -->

        <!-- 상단 타이틀 고정시 사용 -->
        </v-card-text>
      </v-card>

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 연계의뢰인 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      caseId: 0, // 소송 아이디
      caseType: 1, // 소송/자문 타입
      caseInfo: null, // 소송 정보
      clientId: 0, // 의뢰인/상대방 아이디
      clientType: 1, // 의뢰인/상대방 타입(의뢰인 - 1, 상대방 - 2)
      clientInfo: null // 의뢰인 정보
    },
    // 구분: 우측 연계의뢰인 검색폼
    searchForm2: {
      sField: 'name', // 의뢰인 = 'name', 관계인 = 'keyword'
      clName: ''
    },
    // !!구분: 연계 의뢰인 타입(32)
    lwcType: 32,
    // !!구분: 팝업을 열면서 넘어온 subtype 을 매칭시켜야 한다!
    subType: 1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str1: '기일명'
      }
    },
    // 구분: 의뢰인 목록
    clients: [],
    // 구분: 의뢰인/관계인
    clTypes: [
      { text: '의뢰인', value: 'name' },
      { text: '관계인', value: 'keyword' }
    ],
    // 구분: 선택한 의뢰인 정보
    cInfo: {
      id: 0,
      teamId: 1,
      mgroupStr: '',
      teamColor: '',
      teamName: '',
      name: '',
      national: '',
      isStar: 0,
      address: '',
      hp: '',
      tel: '',
      fax: '',
      email: '',
      relPersons: []
    },
    // 구분: 의뢰인/상대방 타이틀
    subTitle1: '연계의뢰인',
    subTitle2: '의뢰인'
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 의뢰인 배열 초기화
      this.clients = []

      // 연계 의뢰인 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.caseId = 0 // 소송 아이디
      this.form.caseType = 1 // 소송/자문 타입 (소송 = 1, 자문 = 2)
      this.form.caseInfo = null // 소송 정보
      this.form.clientId = 0 // 의뢰인 아이디
      this.form.clientType = 1 // 의뢰인/상대방 타입(의뢰인 = 1, 상대방 = 2)
      this.form.clientInfo = null // 의뢰인 정보

      // 선택 의뢰인/상대방 정보 초기화
      await this.initCInfo()

      // 검색폼2 초기화
      this.searchForm2.sField = 'name'
      this.searchForm2.clName = ''
    },
    // 선택 의뢰인/상대방 정보 초기화
    async initCInfo () {
      this.cInfo.id = 0
      this.cInfo.teamId = 1
      this.cInfo.mgroupStr = ''
      this.cInfo.teamColor = ''
      this.cInfo.teamName = ''
      this.cInfo.name = ''
      this.cInfo.national = ''
      this.cInfo.isStar = ''
      this.cInfo.address = ''
      this.cInfo.hp = ''
      this.cInfo.tel = ''
      this.cInfo.fax = ''
      this.cInfo.email = ''
      this.cInfo.relPersons = []
    },
    // !! 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
    // !! this.article 에는 부모인 소송의 정보가 담겨옫나.
    async open (title, options, subtype) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 subtype 에 따라 주체유형(의뢰인/상대방)이 결정되므로 중요하다.
        // subtype 은 clients.type 에 매칭한다
        this.subType = subtype

        // console.log(this.article) // !! props 로 넘어온 부모(소송) 데이터

        // 초기화
        await this.initDialog()

        // 의뢰인/상대방에 맞춰 타이틀 변환
        if (this.subType === 1) {
          this.subTitle1 = '연계의뢰인'
          this.subTitle2 = '의뢰인'
          this.clTypes[0].text = '의뢰인'
        } else {
          this.subTitle1 = '연계상대방'
          this.subTitle2 = '상대방'
          this.clTypes[0].text = '상대방'
        }

        // 연계의뢰인 등록/수정 폼 - 팀과 소송의 정보는 넘어온 부모정보로 픽스된다.
        // 의뢰인 정보는 의뢰인 선택시 매칭된다.
        this.form.teamId = this.article.teamId // 팀 아이디
        this.form.caseId = this.article.id // 소송 아이디
        this.form.caseType = this.article.type1 // 소송 = 1, 자문 = 2 : cases.type1 과 매칭
        this.form.clientType = this.subType // 의뢰인/상대방 타입: 의뢰인 = 1, 상대방 = 2

        // 소송/자문에 따라 보여줄 정보 만들기
        let viewInfo = ''
        if (this.article.type1 === 1) {
          // 소송인 경우
          viewInfo = `${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
        } else {
          // 자문인 경우
          viewInfo = `${this.article.manageNum}`
        }

        // 소송/자문 정보 매칭
        this.form.caseInfo = {
          viewInfo, // 보여줄 정보
          gubun1: this.article.gubun1,
          gubun4: this.article.gubun4,
          gubun5: this.article.gubun5,
          courtName: this.article.courtName,
          caseNum: this.article.caseNum,
          caseName: this.article.caseName,
          manageNum: this.article.manageNum,
          clType: this.article.clType,
          opType: this.article.opType,
          clName: this.article.clName,
          opName: this.article.opName,
          jepanbu: this.article.jepanbu,
          status: this.article.status,
          status2: this.article.status2
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [용도변경] 확인버튼 - 여기서는 그냥 팝업을 닫는 용도
    // async btnConfirmClick () {
    //   try {
    //     let resolve = {
    //       isDeleted: false, // 부모가 삭제되었는지 여부
    //       teamError: false, // 부모의 팀에러
    //       chInfo: null, // 부모의 에러에 대한 메시지
    //       rsMsg: 'success' // resolve 메시지
    //     }
    //     await this.initDialog() // 초기화
    //     this.resolve(resolve) // this.resolve(true)
    //     this.dialog = false
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: 연계하기 버튼으로 용도가 변경됨
    // 기존 연계하기 기능 + 닫기 기능
    // setRelation() 함수를 변형함
    async btnConfirmClick () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `연계 하시겠습니까?`
        if (await pop.open('연계', msg, { color: 'info', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/setRelCaseClient/`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          // !! 기존에는 팝업이 안닫혔기 때문에 목록을 리프레시 하고 선택시켜주고 했지만 이제는 깔끔하게 닫으면 된다.
          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 인명부 등록 다이얼로그 호출 메서드
    // !! 소송/자문의 연계의뢰인 등록폼이므로 components/suit/relClientAddDialog.vue 파일을 호출한다.
    // !! 해당 소송의 팀아이디를 넘겨야 한다.
    // !! 주체유형을 open()에서 넘겨받은 this.subType 으로 넘겨야 한다.
    async relClientAdd () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'relClientAddDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다

        // 팀정보와 주체유형 정보를 가공한 후 인명부 등록 팝업을 연다.
        const clTypes = { 1: '의뢰인', 2: '상대방' }
        const teamInfo = { text: this.article.teamName, value: this.article.teamId }
        const clTypeIfno = { type: this.subType, typeName: clTypes[this.subType] }
        const insertId = await pop.open('인명부', { width: 650, height: 670 }, teamInfo, clTypeIfno)
        if (insertId) {
          // console.log(insertId)
          // 의뢰인 목록 리프레시 하고
          await this.getClients()

          // 방금 등록한 인명부(의뢰인/상대방)를 찾은 후 자동선택
          const cl = this.clients.find(c => c.id === insertId)
          await this.selectPerson2(cl)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 우측 - 해당팀의 의뢰인/상대방 목록 패칭하기
    // 초기로딩시와 검색시 모두 사용됨
    async getClients () {
      try {
        // 의뢰인 타입과 팀아이디, searchForm2 의 정보를 넘긴다!
        const params = {
          type: this.subType, // 넘어온 서브타입 = 의뢰인(1), 상대방(2)
          teamId: this.article.teamId,
          pId: this.article.id, // 부모인 소송/자문의 아이디
          pType: this.article.type1, // 부모의 타입(소송 = 1, 자문 = 2)
          lwcType: this.lwcType, // lwcommon.type
          sField: this.searchForm2.sField,
          clName: this.searchForm2.clName
        }
        const { data } = await this.$axios.get(`lawork/client/getClientsWhere`, { params })
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        this.clients = data.list
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 의뢰인/상대방 상세화면으로 가기
    async goClientDetail (cId) {
      try {
        this.$router.push(`/client/${cId}`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 우측 의뢰인 목록 선택 이벤트
    async selectPerson2 (item) {
      try {
        // 우측 의뢰인 목록 색상도 모두 없앤다
        this.clients.forEach(c => { c.selected = false })
        // 선택한 넘의 색상만 강조
        item.selected = true

        // 선택한 의뢰인정보를 매칭하자!
        this.cInfo = item

        // !! this.form 값 만들기
        this.form.id = 0 // 등록해야 하므로 0
        this.form.clientId = item.id // 의뢰인 아이디

        // 의뢰인 정보를 만든다
        this.form.clientInfo = {
          name: item.name, hp: item.hp, tel: item.tel, fax: item.fax, email: item.email
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러 - 닫는 역할만 한다
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
