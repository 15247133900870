<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str3"
                label="업무자"
                maxlength="50"
                counter="50"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                ref="numStr4"
                v-model.trim="form.str4"
                v-validate="'required|max:9|is_not:0'"
                :error-messages="errors.collect('str4TimeSheet')"
                data-vv-name="str4TimeSheet"
                required
                label="*시간당 업무보수"
                maxlength="9"
                suffix="원"
                @keypress="preventComma"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3"  class="text-center">
              <v-menu
                ref="timeSelector"
                v-model="timeSelector"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    label="시간"
                    prepend-icon="access_time"
                    readonly
                    v-on="on"
                    class="mx-2"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeSelector"
                  v-model="time"
                  format="24hr"
                  @click:minute="setTimeMinutes"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="1" class="text-left">
              <v-btn fab text small dark color="primary"
                @click="calculateBosu"
                class="mt-3"
              >
                <v-icon>mdi-calculator</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" xs="12" sm="4">
              <v-text-field
                ref="numStr2"
                v-model.trim="form.str2"
                v-validate="'required|max:19|is_not:0'"
                :error-messages="errors.collect('str2TS')"
                data-vv-name="str2TS"
                required
                label="*시간보수"
                readonly background-color="grey lighten-3"
                maxlength="19"
                suffix="원"
                class="ml-3"
                @keypress="preventComma"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12" xs="12" class="pt-2">
              <v-select
                v-model="relClientIds"
                :items="relClients"
                :menu-props="{ bottom: true, offsetY: true, maxHeight: '250' }"
                label="부담자"
                no-data-text="연계 의뢰인이 없습니다"
                dense
                prepend-icon="mdi-account-circle"
                style="font-size: 0.785rem !important"
                :readonly="rcReadOnly"
                @change="changeRelClient"
              >
                <template v-slot:selection="{ item }">
                  <v-chip small>
                    <span>{{ item.name }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col> -->
            <!-- !![2021.5.25]소송/자문의 부담자는 단수로 변경됨 -->
            <template v-if="rcReadOnly">
              <v-col cols="12" xs="12" class="pt-0 pb-2">
                <v-text-field
                  v-model.trim="form.rInfo.name"
                  label="부담자"
                  readonly
                  prepend-icon="mdi-account-circle"
                  hide-details
                ></v-text-field>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" xs="12" class="py-2">
                <v-select
                  v-model="form.rId"
                  :items="relClients"
                  :menu-props="{ bottom: true, offsetY: true, maxHeight: '250' }"
                  label="부담자"
                  dense
                  prepend-icon="mdi-account-circle"
                  style="font-size: 1rem !important"
                  @change="changeRelClient"
                  hide-details
                >
                  <template v-slot:selection="{ item }">
                    <span>{{ item.name }}</span>
                  </template>
                </v-select>
              </v-col>
            </template>
            <!-- 내용 -->
            <v-col cols="12" xs="12">
              <v-textarea
                ref="txt1"
                v-model="form.str1"
                outlined
                label="내용"
                placeholder="입력하세요"
                no-resize
                rows="5"
                counter="200"
                :rules="[rules.txtCounter]"
                @update:error="chkValidate = false"
                class="mt-5"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ viewParentInfo }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strToNumber from '@/filters/strToNumber' // string to number
import strDateFormat from '@/filters/strDateFormat'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      fixedClient: false, // 중요:[2021.7.7] 고정된 의뢰인이 넘어오는지 여부를 옵션에 붙였다.
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      // relClientInfos: [], // 연계 의뢰인 정보 배열
      teamId: 1, // 기본팀이 초기값
      type: 26, // 유형(타임시트)
      depth: 2,
      pId: 0,
      pType: 1, // 소송(1), 자문(2)
      pInfo: '', // 정보위치(부모의 정보 중 보여줄 정보 이름)
      rId: 0,
      rType: 3, // 인명부
      rInfo: '',
      isStar: 0,
      isSchedule: 0, // !! 타임시트는 일정표시 안됨
      subtype: 1, // 등록을 제외한 업무는 1로 세팅(등록은 자동입력데이터임)
      gubun1: '타임시트',
      status: 1,
      date1: '', // 업무일
      str1: '', // 내용
      str2: '0', // 타임차지(시간당 업무보수 x 시간)
      str3: '', // 업무자
      str4: '0', // 시간당 업무보수
      str5: '', // 시간
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // 구분: 중요: 연계의뢰인 리스트
    relClients: [
      { text: '청구안함', value: 0, name: '청구안함' }
    ],
    // 구분: 중요: 선택한 연계 의뢰인 배열
    // relClientIds: [ 0 ],
    // 구분: 업무대상자 셀렉트 읽기모드 여부 - 2021.5.24 이후 필요없어짐
    rcReadOnly: false,
    // 사용안함: 업무유형 - 카테고리
    // upmuCates: [],
    // 사용안함: 관리상태
    // upmuStatus: [
    //   { text: '진행', value: 1 },
    //   { text: '완료', value: 2 },
    //   { text: '준비', value: 3 },
    //   { text: '연기', value: 4 },
    //   { text: '취소', value: 5 }
    // ],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str2TS: '타임차지',
        str4TimeSheet: '시간당 업무보수'
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: txt1 유효성 체크
    chkValidate: true,
    // 구분: 일자 - 시간
    date1Selector: false, // 일자 셀렉터
    timeSelector: false, // 시간 셀렉터
    time: '00:00', // 시간 초기값
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 바로가기에 보여줄 부모 정보
    viewParentInfo: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
    // !![2021.3.15] str2, st4 수정
    'form.str2': { // 시간보수
      handler: function (val, oldVal) {
        if (!val) {
          this.form.str2 = '0'
          this.$refs.numStr2.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.str2 = val.replace(/(^0+)/, '')
          }
          this.form.str2 = numberFormatR(this.form.str2)
          this.form.str2 = numberFormat(this.form.str2)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.str2)) {
            this.form.str2 = oldVal
            this.$refs.numStr2.lazyValue = oldVal
          }
        }
      }
    },
    'form.str4': { // 시간당 업무보수
      handler: function (val, oldVal) {
        if (!val) {
          this.form.str4 = '0'
          this.$refs.numStr4.lazyValue = '0'
        } else {
          if (val.length > 1 && val[0] === '0') {
            this.form.str4 = val.replace(/(^0+)/, '')
          }
          this.form.str4 = numberFormatR(this.form.str4)
          this.form.str4 = numberFormat(this.form.str4)

          if (!/^\d{1,3}(,\d{3})*$/gi.test(this.form.str4)) {
            this.form.str4 = oldVal
            this.$refs.numStr4.lazyValue = oldVal
          }
        }
      }
    }
  },

  methods: {
    numberFormat,
    numberFormatR,
    preventComma,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      // this.form.relClientInfos = [] // !! 연계의뢰인 정보 배열
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 1 // 부모 타입: 소송(1), 자문(2)
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보 이름)
      this.form.rId = 0
      this.form.rType = 3 // 업무대상자는 인명부(3)의 의뢰인
      this.form.rInfo = '' // 타임차지부담자 JSON 객체 문자열
      this.form.status = 1
      this.form.isStar = 0
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 일시 초기값(오늘)
      this.form.str1 = '' // 내용
      this.form.str2 = '0' // 계산된 타임차지
      this.form.str3 = '' // 업무자
      this.form.str4 = '0' // 시간당 업무보수
      this.form.str5 = '' // 시간
      this.form.parentInfo = ''

      // !! 연계의뢰인 셀렉트 초기화
      this.relClients = [
        { text: '청구안함', value: 0, name: '청구안함', rInfo: '' }
      ]
      // !! 연계의뢰인 선택 초기화
      // this.relClientIds = [ 0 ]
      // !! 셀렉트 읽기모드 여부 - 2021.5.24 이후 필요없어짐
      this.rcReadOnly = false

      this.time = '00:00' // 시간 초기값
      this.index = -1 // 수정용 인덱스 초기화

      this.options.fixedClient = false // 중요: 고정의뢰인여부를 초기화 해야한다.
    },
    // 구분:
    async open (title, options, index = -1, obj = {}, relClients) {
      try {
        await this.initDialog() // 초기화 - [2021.7.7] 순서변경.. 초기화를 맨 처음에

        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(this.article) // !! props 로 넘어온 부모 데이터

        // !! 연계 의뢰인 셀렉트 매칭
        // 중요:[2021.7.7] 고정된 의뢰인이 넘어온 경우 처리 추가
        if (this.options.fixedClient) {
          // 옵션으로 고정의뢰인이 왔다는 표기가 넘어오면 relClients 에는 1개의 의뢰인 정보만 들어있는 배열이다.
          this.relClients = relClients
          this.form.rId = relClients[0].value // 부담자 아이디
          this.form.rInfo = relClients[0].rInfo // 부담자 객체
        } else {
          // 고정된 의뢰인이 아니면 연계의뢰인 배열에 넘어온 넘들을 추가한다.
          this.relClients.push(...relClients)
        }

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id
        this.form.pType = this.article.type1

        // !! 소송/자문에 따른 부모 정보 - 2021.3.19 수정
        if (this.article.type1 === 1) { // 소송인 경우
          this.viewParentInfo = `${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
          this.form.parentInfo = this.article.caseNum
        } else { // 자문
          this.viewParentInfo = `${this.article.manageNum}`
          this.form.parentInfo = this.article.manageNum
        }

        // 중요:[2021.3.19] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo)

        // !![2021.4.26] 등록시 업무자 초기값을 로그인 유저의 이름으로
        this.form.str3 = this.$store.state.ui.name

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          // !! 업무대상자인 인명부(의뢰인) 정보가 있는 경우 처리
          if (obj.rId) {
            // !! rId, rInfo 를 채운다
            this.form.rId = obj.rId
            this.form.rInfo = obj.rInfo // 부담자객체문자열

            // !![2021.5.25] 복수가 아니라서 아래는 없어짐
            // 1) 셀렉트 고정
            // this.relClients = [
            //   { text: obj.rInfo, value: obj.rId, name: obj.rInfo }
            // ]
            // 2) 셀렉트 선택 & readOnly
            // this.relClientIds = [ obj.rId ]
            // this.rcReadOnly = true
            // 3) 폼의 연계의뢰인 정보 배열은 초기화 시키고
            // this.form.relClientInfos = []
            // 4) 폼의 정보 고정
            // this.form.relClientInfos.push({ id: obj.rId, name: obj.rInfo })

            // !!중요: 하지만 부담자가 있으면 고정시킨다
            this.rcReadOnly = true
          }

          this.form.gubun1 = obj.gubun1
          this.form.status = obj.status
          this.form.isStar = obj.isStar

          this.form.str1 = obj.str1 // 내용
          this.form.str2 = obj.str2.toString() // !! 계산된 타임차지값(정수값을 문자열로 받아들인다)
          this.form.str3 = obj.str3 // 업무자
          this.form.str4 = obj.str4 // 시간당업무보수
          this.form.str5 = obj.str5 // 시간

          // 일자-시간 처리
          this.form.date1 = obj.date1.substr(0, 10)
          this.time = obj.str5

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 등록/수정
    async agree () {
      try {
        // 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // textarea 의 글자수가 200 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules.txtCounter(this.$refs.txt1.value) === true) {
          this.chkValidate = true
        }
        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 내용 항목의 글자수를 줄여주세요.')

        // 한번더 - 금액에 대해 숫자만 남긴다
        // this.form.str2 = this.form.str2.replace(/[^0-9]/g, '')

        // str5 는 time 을 저장한다.
        this.form.str5 = this.time

        // 일자 처리 - 시분초를 붙여준다.
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:16:01`

        // 중요:[2021.7.7] 부담자(의뢰인객체)가 선택된 경우 JSON 문자열로 변환해서 보낸다.
        if (this.form.rInfo && typeof this.form.rInfo === 'object') {
          this.form.rInfo = JSON.stringify(this.form.rInfo)
        }

        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/case/setRelTimesheet`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = 'success'

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          // const { data } = await this.$axios.get(`lawork/case/deleteLwcommon/${this.form.id}`)
          // !!2021.2.17 - POST 방식으로 변경하며 필요 데이터만 파라미터로 넘긴다!
          const params = {
            id: this.form.id,
            teamId: this.form.teamId,
            type: this.form.type,
            depth: this.form.depth,
            pId: this.form.pId,
            pType: this.form.pType,
            pInfo: this.form.parentInfo
          }
          const { data } = await this.$axios.post(`lawork/case/removeLwc`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed'

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve('removed')
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !![2021.5.24] 대대적인 수정 - 업무대상자 셀렉트 체인지 이벤트
    async changeRelClient () {
      try {
        // 주의:[2021.7.7] 부담자의 이름에서 의뢰인객체(rInfo)로 변경됨
        if (this.form.rId) {
          const rc = this.relClients.find(rc => rc.value === this.form.rId)
          // this.form.rInfo = rc.name
          this.form.rInfo = rc.rInfo // 부담자는 의뢰인 객체
        } else {
          this.form.rInfo = ''
        }

        // const index = this.relClientIds.indexOf(0)
        // if (index === 0) {
        //   // 구분안함(0) 이 첫번째에 있으면 구분안함을 지워야 한다.
        //   this.relClientIds.splice(index, 1)
        // } else if (index >= 0) {
        //   // 구분안함(0) 이 중간에 들어오면 모두 지우고 0을 넣어야 한다.
        //   this.relClientIds = [ 0 ]
        // }

        // // 선택된게 아무것도 없으면 구분안함(0)을 선택한다.
        // if (this.relClientIds.length === 0) {
        //   this.relClientIds = [ 0 ]
        // }

        // // 폼의 연계의뢰인 정보 배열은 초기화 시키고
        // this.form.relClientInfos = []

        // // 구분안함이 아닌 항목이 선택되었으면. 첫번째 항목의 값은 1이 아니다.
        // if (this.relClientIds[0] > 0) {
        //   for (let value of this.relClientIds) {
        //     // 선택된 의뢰인을 this.relClients 에서 찾아서 this.form.relClientInfos 에 넣는다
        //     const selected = this.relClients.find(rc => rc.value === value)
        //     this.form.relClientInfos.push({ id: selected.value, name: selected.name })
        //   }
        // }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 시간보수 계산: 시간당 업무보수 x 시간(시:분) = 시간보수
    calculateBosu () {
      try {
        // 시간당 업무보수가 비어있지 않아야 함
        if (!this.form.str4) throw new Error(`시간당 업무보수를 먼저 입력하십시오.`)
        if (this.time === '00:00') throw new Error(`시간을 선택하십시오.`)

        // 시간당 업무보수 정수화
        // const costPerTime = parseInt(this.form.str4, 10)
        const costPerTime = strToNumber(this.form.str4) // !![2021.3.15] 수정
        if (!costPerTime) throw new Error(`시간당 업무보수를 먼저 입력하십시오.`)

        // 시, 분을 정수화
        const tm = this.time.split(':')
        const tt = Number(tm[0])
        const mm = Number(tm[1])

        let result = 0
        result = (costPerTime * tt) + (costPerTime * (mm / 60))

        // !! 정수로 계산 후 다시 문자열로 변환한다
        // !![2021.3.15] 수정 - numberformat 적용
        this.form.str2 = numberFormat(Math.floor(result).toString())
      } catch (e) {
        this.sbpop(e)
      }
    },
    setTimeMinutes () {
      try {
        this.$refs.timeSelector.save(this.time)
        this.calculateBosu() // 자동 계산
      } catch (e) {
        this.sbpop(e)
      }
    },
    // // 사용안함: 현재 조직원이름을 실시간으로 가져오는 함수 - vuex를 사용하지 않는 이유는 실시간성이 중요해서임
    // async getWnames () {
    //   try {
    //     const { data } = await this.$axios.get('admin/staff/getAllHoldStaffs/')
    //     if (!data.success) throw new Error(`list error: ${data.message}`)
    //     return { staffs: data.list }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      this.$refs.date1Selector.save(date)
    },
    // !! 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      if (this.article.type1 === 1) { // 수금인 경우
        // !! 현재 경로가 case 디렉토리가 아닌 경우만 case/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('case') !== -1)) {
          this.$router.push(`/case/${this.article.id}`)
        }
      } else { // 자문일때
        // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
        if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
          this.$router.push(`/advice/${this.article.id}`)
        }
      }
    }
  }
}
</script>
