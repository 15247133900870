<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="btnConfirmClick"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">연계</v-btn>

      <v-btn
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">해제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height - 150"
        :max-height="options.height - 150"
      >
        <v-card-text class="ma-0 pa-0"> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <!-- 관련사건 팝업은 다른 팝업과 다르게 class="ma-0 pa-0" 으로 맞춰야 한다 -->
      <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-link-variant</v-icon>연계정보등록
                    </v-toolbar-title>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    class="text-body-2 px-0 pt-5 pb-3 mx-2 mt-0 mb-2"
                  >
                    <v-toolbar flat class="my-1">
                      <v-subheader class="ma-0 px-0 pt-0 pb-8">본사건</v-subheader>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="oCaseNum"
                          label=""
                          filled
                          dense
                          class="text-body-2 mx-2 mt-6 mb-10 pl-5 pr-0"
                          readonly
                        ></v-text-field>
                      </div>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="form.oCaseCaption"
                          v-validate="'required|max:30'"
                          :error-messages="errors.collect('oCaseCaption')"
                          data-vv-name="oCaseCaption"
                          maxlength="30"
                          counter="30"
                          required
                          label="*연계정보"
                          hint="예)1심사건"
                          dense
                          class="text-body-2 mx-0 mt-6 mb-10 pa-3"
                        ></v-text-field>
                      </div>
                    </v-toolbar>
                    <!-- <v-divider class="my-2"></v-divider> -->
                    <v-toolbar dense flat class="my-1">
                      <v-subheader class="ma-0 px-0 pt-0 pb-8">연계사건</v-subheader>
                      <div style="width: 370px;">
                        <v-text-field
                          v-model="tCaseNum"
                          label=""
                          filled
                          dense
                          class="text-body-2 mx-2 mt-6 mb-10 pl-2 pr-0"
                          readonly
                        >
                          <!-- <template v-slot:append-outer>
                            <v-icon small
                              color="primary"
                              class="mt-1"
                              @click.stop="goCaseDetail(form.tCase)"
                            >mdi-launch</v-icon>
                          </template> -->
                        </v-text-field>
                      </div>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="form.tCaseCaption"
                          v-validate="'required|max:30'"
                          :error-messages="errors.collect('tCaseCaption')"
                          data-vv-name="tCaseCaption"
                          maxlength="30"
                          counter="30"
                          required
                          label="*연계정보"
                          hint="예)항소심"
                          dense
                          class="text-body-2 mx-0 mt-6 mb-10 pa-3"
                        ></v-text-field>
                      </div>
                    </v-toolbar>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  // props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 관련사건 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      lwcType: 31, // lwcommon.type = 31(관련사건)
      oCase: null, // 본사건 정보
      tCase: null, // 연계사건 정보
      oCaseCaption: '', // 본사건 입력정보
      tCaseCaption: '' // 연계사건 입력정보
    },
    // !!구분: 관련사건 타입(31)
    lwcType: 31,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        oCaseCaption: '연계정보',
        tCaseCaption: '연계정보'
      }
    },
    // 구분: 본사건, 연계사건 사건(관리) 번호
    oCaseNum: '',
    tCaseNum: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 관련사건 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.oCase = null // 본사건 정보
      this.form.tCase = null // 연계사건 정보
      this.form.oCaseCaption = '' // 본사건 입력정보
      this.form.tCaseCaption = '' // 연계사건 입력정보

      // 연계정보 등록에 표시되는 본사건, 연계사건 사건(관리)번호
      // // this.oCaseNum = `${this.article.courtName} ${this.article.caseNum}`
      // this.oCaseNum = `${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
      // this.tCaseNum = ''
    },
    // !! 제목, 옵션, lwcommon 데이터
    async open (title, options, item) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog() // 초기화

        // 본사건, 관련사건 사건번호를 보여주기(소송/자문 공통으로 item.pInfo 에 들어가 있다.)
        // !![2021.3.31] 수정 - item.rInfo 는 파싱된 채로 넘어온다.
        const pInfo = JSON.parse(item.pInfo)

        this.oCaseNum = pInfo.type1 === 1 ? pInfo.caseNum : pInfo.manageNum
        this.tCaseNum = item.rInfo.type1 === 1 ? item.rInfo.caseNum : item.rInfo.manageNum

        // 넘길 폼값 매칭
        this.form.id = item.id
        this.form.teamId = item.teamId
        // 원본사건
        // 소송/자문 모두 대응을 위해 caseNum,manageNum에 모두 pInfo 를 대입한다.
        this.form.oCase = {
          id: item.pId,
          type: item.pType,
          caseNum: pInfo.caseNum, // 주의: 위에서 파싱한 pInfo를 사용해야 한다.
          manageNum: pInfo.manageNum // 주의: 위에서 파싱한 pInfo를 사용해야 한다.
        }
        // 목적사건(관련사건)
        // 소송/자문 모두 대응을 위해 caseNum,manageNum에 모두 rInfo 를 대입한다.
        this.form.tCase = {
          id: item.rId,
          type: item.rType,
          caseNum: item.rInfo.caseNum,
          manageNum: item.rInfo.manageNum
        }
        this.form.oCaseCaption = item.str1 // 본사건 입력정보
        this.form.tCaseCaption = item.str2 // 연계사건 입력정보

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 연계
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 다시 한 번 입력값 검증
        if (this.form.oCaseCaption === '' || this.form.tCaseCaption === '') {
          throw new Error(`본사건과 연계사건의 연계정보를 입력하셔야 합니다.`)
        }
        // 넘기는 폼 객체의 사건정보가 없으면 에러
        if (!this.form.oCase || !this.form.tCase) {
          throw new Error(`오류가 발생하였습니다. 등록할 수 없습니다.`)
        }
        // console.log(this.form)

        // !! 수정
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        const msg = `관련사건을 연계 하시겠습니까?`
        if (await pop.open('관련사건 연계', msg, { color: 'info', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/setConnectCase/`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          // 이미 삭제되었거나 팀이 변경된경우 에러 => 본사건/관련사건 전부 조사한 결과
          // 일단 닫고 에러처리는 상세페이지에서 처리함.
          if (resolve.isDeleted || resolve.teamError) {
            //
          } else {
            this.$store.commit('SB_POP', { msg: '적용되었습니다.', color: 'info' })
          }
          await this.initDialog()
          this.resolve(resolve)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 해제
    async remove () {
      try {
        // console.log(this.form)
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `사건 상호 간의 연결을 해제합니다.<br>그러나, 사건 정보 자체가 삭제되는 것은 아닙니다.`
        if (await pop.open('해제', msg, { color: 'error', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/deleteConnectCase`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed'

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 소송/자문 상세화면으로 가기
    async goCaseDetail (item) {
      try {
        if (item.type === 1) { // 소송
          this.$router.push(`/case/${item.id}`)
        } else { // 자문
          this.$router.push(`/advice/${item.id}`)
        }
        await this.initDialog() // 초기화
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
