<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="btnConfirmClick">연계하기</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height - 150"
        :max-height="options.height - 150"
      >
        <v-card-text class="ma-0 pa-0"> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <!-- 관련사건 팝업은 다른 팝업과 다르게 class="ma-0 pa-0" 으로 맞춰야 한다 -->
      <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-format-list-bulleted</v-icon>리스트
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <div style="width: 80px;">
                      <v-select
                        v-model="searchForm.sType"
                        :items="sTypes"
                        item-value="value"
                        item-text="text"
                        label=""
                        dense
                        style="font-size: 0.785rem !important"
                        class="mt-3 ml-2"
                      ></v-select>
                    </div>
                    <div style="width: 140px;">
                      <v-select
                        v-model="searchForm.sField"
                        :items="sFields"
                        item-value="value"
                        item-text="text"
                        label=""
                        dense
                        style="font-size: 0.785rem !important"
                        class="mt-3 ml-2"
                      ></v-select>
                    </div>
                    <div style="width: 200px">
                      <v-text-field
                        v-model="searchForm.sValue"
                        label=""
                        maxlength="20"
                        clearble
                        class="mt-3 mx-2"
                        @keypress.enter="getCases"
                      ></v-text-field>
                    </div>
                    <v-btn small outlined text color="primary"
                      class="ma-0"
                      @click="getCases"
                    >
                      <v-icon small left>
                        mdi-magnify
                      </v-icon>
                      검색
                    </v-btn>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    min-height="170"
                    max-height="170"
                    class="overflow-auto text-body-2 pa-1 mx-2 mt-0 mb-3"
                  >
                    <v-alert v-show="cases.length <= 0"
                      text color="blue-grey" class="text-body-2"
                    >
                      해당 정보가 없습니다.(아래의 [신규등록]을 통해 연계사건을 신규등록할 수 있습니다.)
                    </v-alert>
                    <v-row
                      v-for="(item, i) in cases"
                      :key="i"
                      no-gutters
                    >
                      <v-col cols="12">
                        <v-chip
                          :disabled="item.isConnected"
                          :color="item.selected ? 'primary' : 'white'"
                          :dark="item.selected ? true : false"
                          class="ma-0"
                          @click="selectCase(item)"
                        >
                          <v-avatar left>
                            <v-icon small>
                              {{ item.type1 === 1 ? 'mdi-briefcase' : 'mdi-briefcase-account' }}
                            </v-icon>
                          </v-avatar>
                          <span class="font-weight-bold text-body-2">{{ item.courtName ? `${item.courtName}&nbsp;` : '' }}</span>
                          <span v-if="item.type1 === 1" class="font-weight-bold text-body-2 mr-1">
                            {{ item.caseNum }}{{ item.manageNum ? `/${item.manageNum}` : '' }}
                          </span>
                          <span v-else class="font-weight-bold text-body-2 mr-1">
                            {{ item.manageNum }}
                          </span>
                          <span class="text-body-2 mr-1">{{ item.caseName }}</span>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-plus</v-icon>신규등록
                    </v-toolbar-title>
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    class="text-body-2 pa-0 mx-2 mt-0 mb-3"
                  >
                    <v-toolbar dense flat>
                      <v-icon small left
                        @click="isCopyed = !isCopyed"
                        style="cursor: pointer;"
                      >
                        {{ isCopyed ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                      </v-icon>
                      <span
                       class="text-caption mr-5"
                       style="cursor: pointer;"
                       @click="isCopyed = !isCopyed"
                      >
                        의뢰인,상대방 상세정보 복사
                      </span>
                      <v-btn small color="primary" depressed class="mx-2"
                        @click="addNewCase"
                      >
                        <v-icon left small>mdi-briefcase</v-icon>
                        소송등록
                      </v-btn>
                      <v-btn small color="orange" depressed
                        @click="addNewAdvice"
                      >
                        <v-icon left small>mdi-briefcase-account</v-icon>
                        자문등록
                      </v-btn>
                    </v-toolbar>
                  </v-card>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat>
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-link-variant</v-icon>연계정보등록
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-btn small depressed
                      :color="relBtnColor"
                      :disabled="relBtnDisabled"
                      @click="setRelation"
                    >
                      {{ relBtnCaption }}
                    </v-btn> -->
                  </v-toolbar>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    outlined
                    elevation="0"
                    class="text-body-2 px-0 pt-5 pb-3 mx-2 mt-0 mb-2"
                  >
                    <v-toolbar flat class="my-1">
                      <v-subheader class="ma-0 px-0 pt-0 pb-8">본사건</v-subheader>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="oCaseNum"
                          label=""
                          filled
                          dense
                          class="text-body-2 mx-2 mt-6 mb-10 pl-5 pr-0"
                          readonly
                        ></v-text-field>
                      </div>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="form.oCaseCaption"
                          v-validate="'required|max:30'"
                          :error-messages="errors.collect('oCaseCaption')"
                          data-vv-name="oCaseCaption"
                          maxlength="30"
                          counter="30"
                          required
                          label="*연계정보"
                          hint="예)1심사건"
                          dense
                          class="text-body-2 mx-0 mt-6 mb-10 pa-3"
                        ></v-text-field>
                      </div>
                    </v-toolbar>
                    <!-- <v-divider class="my-2"></v-divider> -->
                    <v-toolbar dense flat class="my-1">
                      <v-subheader class="ma-0 px-0 pt-0 pb-8">연계사건</v-subheader>
                      <div style="width: 370px;">
                        <v-text-field
                          v-model="tCaseNum"
                          label=""
                          filled
                          dense
                          class="text-body-2 mx-2 mt-6 mb-10 pl-1 pr-0"
                          readonly
                        ></v-text-field>
                      </div>
                      <div style="width: 380px;">
                        <v-text-field
                          v-model="form.tCaseCaption"
                          v-validate="'required|max:30'"
                          :error-messages="errors.collect('tCaseCaption')"
                          data-vv-name="tCaseCaption"
                          maxlength="30"
                          counter="30"
                          required
                          label="*연계정보"
                          hint="예)항소심"
                          dense
                          class="text-body-2 mx-0 mt-6 mb-10 pa-3"
                        ></v-text-field>
                      </div>
                    </v-toolbar>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 관련사건 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      lwcType: 31, // !! lwcommon.type = 31(관련사건)
      oCase: null, // 본사건 정보
      tCase: null, // 연계사건 정보
      oCaseCaption: '', // 본사건 입력정보
      tCaseCaption: '' // 연계사건 입력정보
    },
    // !!구분: 관련사건 타입(31)
    lwcType: 31,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        oCaseCaption: '연계정보',
        tCaseCaption: '연계정보'
      }
    },
    // 구분: 소송/자문 목록
    cases: [],
    // 구분: 소송/자문 검색폼
    searchForm: {
      sType: 1, // 소송(1), 자문(2)
      sField: 1,
      sValue: ''
    },
    // 구분: 검색 - 소송/자문 셀렉트
    sTypes: [
      { text: '소송', value: 1 },
      { text: '자문', value: 2 }
    ],
    // 구분: 검색 - 검색 셀렉트
    sFields: [
      { text: '사건당사자', value: 1 },
      { text: '사건(관리)번호', value: 2 },
      { text: '사건명', value: 3 },
      { text: '관할', value: 4 },
      { text: '재판(담당)부', value: 5 }
    ],
    // 구분: 선택한 사건 정보
    cInfo: {
      id: 0,
      teamId: 1
    },
    // 구분: 연계버튼 활성화 여부와 버튼 옵션
    // relBtnDisabled: true,
    // relBtnColor: 'primary',
    // relBtnCaption: '연계하기',
    // 구분: 의뢰인,상대방 상세정보 복사 여부
    isCopyed: true,
    // 구분: 본사건, 연계사건 사건(관리) 번호
    oCaseNum: '',
    tCaseNum: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 소송/자문 목록 배열 초기화
      this.cases = []

      // 관련사건 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.oCase = null // 본사건 정보
      this.form.oCaseCaption = '' // 본사건 입력정보
      this.form.tCase = null // 연계사건 정보
      this.form.tCaseCaption = '' // 연계사건 입력정보

      // 검색폼 초기화
      this.searchForm.sType = 1
      this.searchForm.sField = 1
      this.searchForm.clName = ''

      // 연계정보 등록에 표시되는 본사건, 연계사건 사건(관리)번호
      if (this.article.type1 === 1) {
        // 소송인 경우
        this.oCaseNum = `${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
      } else {
        // 자문인 경우
        this.oCaseNum = `${this.article.manageNum}`
      }

      this.tCaseNum = ''

      // 연계버튼 활성화 여부
      // this.relBtnDisabled = true
      // this.relBtnColor = 'primary'
      // this.relBtnCaption = '연계하기'

      this.isCopyed = true // 의뢰인,상대방 상세정보 복사 초기화
    },
    // !! 제목, 옵션
    async open (title, options) {
      try {
        // console.log(this.article) // !! props 로 넘어온 부모(소송) 데이터
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        await this.initDialog() // 초기화

        // await this.getCases() // 초기 로딩시 소송/자문 목록 패칭

        // 등록 폼 중 본사건에 관련된 것만 픽스한다.
        this.form.teamId = this.article.teamId // 팀 아이디
        // 본사건 정보
        this.form.oCase = {
          id: this.article.id,
          type: this.article.type1,
          courtName: this.article.courtName,
          caseNum: this.article.caseNum,
          caseName: this.article.caseName,
          manageNum: this.article.manageNum,
          isStar: this.article.isStar,
          jepanbu: this.article.jepanbu,
          endDate: this.article.endDate,
          endType: this.article.endType,
          clType: this.article.clType,
          clName: this.article.clName,
          clGusok: this.article.clGusok,
          opType: this.article.opType,
          opName: this.article.opName,
          opGusok: this.article.opGusok,
          status: this.article.status
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [용도변경] 확인버튼 - 여기서는 그냥 팝업을 닫는 용도
    // async btnConfirmClick () {
    //   try {
    //     let resolve = {
    //       isDeleted: false, // 부모가 삭제되었는지 여부
    //       teamError: false, // 부모의 팀에러
    //       chInfo: null, // 부모의 에러에 대한 메시지
    //       rsMsg: 'success' // resolve 메시지
    //     }
    //     await this.initDialog() // 초기화
    //     this.resolve(resolve) // this.resolve(true)
    //     this.dialog = false
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 구분: 연계하기 버튼으로 용도가 변경됨
    // 기존 연계하기 기능 + 닫기 기능
    // setRelation() 함수를 변형함
    async btnConfirmClick () {
      try {
        // 리턴객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }

        // #1 본사건의 정보가 없으면 당연히 에러
        if (!this.form.oCase) throw new Error(`오류가 발생하였습니다. 등록할 수 없습니다.`)

        // #2 연계사건 정보가 없다면(셀렉트 선택이 없는 경우) 그냥 닫기 기능을 한다.
        if (!this.form.tCase) {
          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
          return
        }

        // #3 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 다시 한 번 입력값 검증
        if (this.form.oCaseCaption === '' || this.form.tCaseCaption === '') {
          throw new Error(`본사건과 연계사건의 연계정보를 입력하셔야 합니다.`)
        }

        // !! 등록
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        const msg = `관련사건을 연계 하시겠습니까?`
        if (await pop.open('관련사건 연계', msg, { color: 'info', width: 400 })) {
          const { data } = await this.$axios.post(`lawork/case/setConnectCase/`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          // 팝업창 닫기
          await this.initDialog()
          this.resolve(resolve)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 소송 등록 다이얼로그 호출 메서드
    // !! 인명부의 새로운 소송 등록폼(components/client/addNewCaseDialog.vue 파일)을 호출한다.
    // !! 해당 인명부의 팀아이디를 넘겨야 한다.
    // !! [2021.2.25] 의뢰인/상대방 구속여부 - clGusok, opGusok 추가
    async addNewCase () {
      try {
        let clInfo = null
        if (this.isCopyed) { // 의뢰인, 상대방 상세정보 복사인 경우
          // console.log(this.article)
          clInfo = {
            clType: this.article.clType,
            opType: this.article.opType,
            clName: this.article.clName,
            opName: this.article.opName,
            clGusok: this.article.clGusok,
            opGusok: this.article.opGusok
          }
        }
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'addNewCaseDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 팀정보를 가공한 후 소송 등록 팝업을 연다. 팀정보는 수정이 불가능하게 고정된다.
        const teamInfo = { text: this.article.teamName, value: this.article.teamId }
        const insertId = await pop.open('소송', { width: 700, height: 670 }, teamInfo, clInfo)
        if (insertId) {
          // 소송 목록 리프레시 하고
          await this.getCases()

          // 방금 등록한 소송을 찾은 후 자동선택
          const ca = this.cases.find(c => c.id === insertId)
          await this.selectCase(ca)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 자문 등록 다이얼로그 호출 메서드
    // !! [2021.2.25] 의뢰인/상대방 구속여부 - clGusok, opGusok 추가
    async addNewAdvice () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `고문계약으로 인한 자문사건 등록은해당 의뢰인페이지에서 등록하십시오.`
        if (await pop.open('확인', msg, { color: 'info', width: 470 })) {
          // ----- 자문 등록 팝업 시작
          let clInfo = null
          if (this.isCopyed) { // 의뢰인, 상대방 상세정보 복사인 경우
            // console.log(this.article)
            clInfo = {
              clType: this.article.clType,
              opType: this.article.opType,
              clName: this.article.clName,
              opName: this.article.opName,
              clGusok: this.article.clGusok,
              opGusok: this.article.opGusok
            }
          }
          // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
          const target = 'addNewAdviceDialog'
          const pop = await this.findParentRefs(this.$parent, target)
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 팀정보를 가공한 후 자문 등록 팝업을 연다. 팀정보는 수정이 불가능하게 고정된다.
          const teamInfo = { text: this.article.teamName, value: this.article.teamId }
          const insertId = await pop.open('자문', { width: 700, height: 670 }, teamInfo, clInfo)
          if (insertId) {
            // 자문 목록 리프레시 하고
            await this.getCases()

            // 방금 등록한 자문을 찾은 후 자동선택
            const ca = this.cases.find(c => c.id === insertId)
            await this.selectCase(ca)
          }
          // ---- 자문 등록 팝업 끝
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 해당팀의 소송/자문 목록 패칭하기
    // 초기로딩시와 검색시 모두 사용됨
    async getCases () {
      try {
        // 팀아이디, searchForm 의 정보를 넘긴다!
        const params = {
          teamId: this.article.teamId,
          pId: this.article.id, // 부모인 소송/자문의 아이디
          pType: this.article.type1, // 부모의 타입(소송 = 1, 자문 = 2)
          lwcType: this.lwcType, // lwcommon.type
          sType: this.searchForm.sType,
          sField: this.searchForm.sField,
          sValue: this.searchForm.sValue
        }
        const { data } = await this.$axios.get(`lawork/case/getRelCasesWhere`, { params })
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        this.cases = data.list

        // 연계정보와 뷰 초기화
        await this.initRelProc()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계사건에 관련된 부분만 초기화 한다!
    async initRelProc () {
      // validation 초기화
      this.$validator.reset()

      // 연계정보 뷰 초기화
      if (this.article.type1 === 1) {
        // 소송인 경우
        this.oCaseNum = `${this.article.courtName} ${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
      } else {
        // 자문인 경우
        this.oCaseNum = `${this.article.manageNum}`
      }
      this.tCaseNum = ''

      // 연계등록 폼 > 연계사건 부분 초기화
      this.form.oCaseCaption = '' // 본사건 입력정보
      this.form.tCase = null // 연계사건 정보
      this.form.tCaseCaption = '' // 연계사건 입력정보

      // 연계버튼 비활성화
      // this.relBtnDisabled = true
    },
    // 구분: 소송/자문 목록 선택 이벤트
    async selectCase (item) {
      try {
        // validation 초기화
        this.$validator.reset()

        // console.log(item)
        // 소송/자문 목록 색상을 모두 없앤다
        this.cases.forEach(c => { c.selected = false })
        // 선택한 넘의 색상만 강조
        item.selected = true

        // 그리고 연계버튼 활성화
        // this.relBtnDisabled = false
        // this.relBtnColor = 'primary'
        // this.relBtnCaption = '연계하기'

        // 연계등록 폼 > 연계사건 부분 매칭. 고쳐: 자문은?
        this.form.tCase = {
          id: item.id,
          type: item.type1,
          courtName: item.courtName,
          caseNum: item.caseNum,
          caseName: item.caseName,
          manageNum: item.manageNum,
          isStar: item.isStar,
          jepanbu: item.jepanbu,
          endDate: item.endDate,
          endType: item.endType,
          clType: item.clType,
          clName: item.clName,
          clGusok: item.clGusok,
          opType: item.opType,
          opName: item.opName,
          opGusok: item.opGusok,
          status: item.status
        }

        // 연계정보 > 연계사건 뷰
        if (item.type1 === 1) {
          this.tCaseNum = `${item.courtName} ${item.caseNum}${item.manageNum ? `/${item.manageNum}` : ''}`
        } else {
          this.tCaseNum = `${item.manageNum}`
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러 - 닫는 역할만 한다
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.4.28] 연계하기 버튼 클릭 이벤트 - 선택한 소송/자문과 양방향 관계를 맺는다!
    // btnConfirmClick() 함수로 기능이 이전됨
    async setRelation () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 다시 한 번 입력값 검증
        if (this.form.oCaseCaption === '' || this.form.tCaseCaption === '') {
          throw new Error(`본사건과 연계사건의 연계정보를 입력하셔야 합니다.`)
        }
        // 넘기는 폼 객체의 사건정보가 없으면 에러
        if (!this.form.oCase || !this.form.tCase) {
          throw new Error(`오류가 발생하였습니다. 등록할 수 없습니다.`)
        }

        // !! 등록
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        const msg = `관련사건을 연계 하시겠습니까?`
        if (await pop.open('관련사건 연계', msg, { color: 'info', width: 400 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/case/setConnectCase/`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          // 이미 삭제되었거나 팀이 변경된경우 에러 => 본사건/관련사건 전부 조사한 결과
          // 일단 닫고 에러처리는 상세페이지에서 처리함.
          if (resolve.isDeleted || resolve.teamError) {
            await this.initDialog()
            this.resolve(resolve)
            this.dialog = false
          } else {
            await this.initRelProc()
            await this.getCases() // 소송/자문 목록 리프레시
            this.$store.commit('SB_POP', { msg: '적용되었습니다.', color: 'info' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
