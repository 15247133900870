import { render, staticRenderFns } from "./editTxt1Dialog.vue?vue&type=template&id=284a08ef&scoped=true"
import script from "./editTxt1Dialog.vue?vue&type=script&lang=js"
export * from "./editTxt1Dialog.vue?vue&type=script&lang=js"
import style0 from "./editTxt1Dialog.vue?vue&type=style&index=0&id=284a08ef&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284a08ef",
  null
  
)

export default component.exports