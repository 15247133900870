<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">확인</v-btn>

      <!-- <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn> -->

      <v-btn
        slot="star-area"
        icon
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>

            <v-col cols="12" xs="12">
              <v-alert
                outlined
                border="left"
                type="warning"
                icon="mdi-alert"
                dense
                class="text-subtitle-1"
              >
                수정 및 삭제, 고문료 등의 관리는 해당 의뢰인에서 가능합니다
              </v-alert>
            </v-col>
            <!-- 담당부서 -->
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="teamName"
                label="담당부서"
                readonly
                class="mr-2"
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="teamColor"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 위임인 -->
            <v-col cols="12" xs="12" class="py-2">
              <v-text-field
                v-model="clientName"
                label="위임인"
                prepend-icon="mdi-account-circle"
                readonly
                color="primary"
                class="text-input-font-color__primary"
              >
                <template v-slot:prepend>
                  <v-icon color="primary">mdi-account-circle</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <!-- 계약번호 -->
            <v-col cols="12" xs="12" sm="6">
              <v-row
                no-gutters
                align="center"
                justify="start"
              >
                <div style="width:230px">
                  <v-text-field
                    v-model.trim="form.gubun2"
                    label="계약번호"
                    placeholder="조직내부계약번호"
                    maxlength="30"
                    readonly
                  ></v-text-field>
                </div>
              </v-row>
            </v-col>
            <!-- 수임일 -->
            <v-col cols="12" xs="12" sm="6" class="pl-5">
              <v-text-field
                v-model.trim="form.date1"
                label="수임일"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </v-col>
            <!-- 계약기간 & 약정업무시간 -->
            <v-col cols="12" xs="12">
              <v-row no-gutter>
                <!-- 계약기간 -->
                <v-col cols="12" xs="12" sm="6">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" class="mt-0">
                      <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">계약기간</span>
                    </v-col>
                    <v-col cols="12" xs="12">
                      <v-row no-gutters justify="space-around">
                        <v-text-field
                          v-model.trim="form.date2"
                          label="시작일"
                          prepend-icon="event"
                          hide-details
                          style="max-width: 160px;"
                          readonly
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="form.date3"
                          label="만료일"
                          prepend-icon="event"
                          hide-details
                          style="max-width: 160px;"
                          readonly
                        ></v-text-field>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- 약정업무시간 -->
                <v-col cols="12" xs="12" sm="6">
                  <v-row no-gutters>
                    <v-col cols="12" xs="12" class="mt-0">
                      <span class="text-subtitle-2 mt-0 mb-2 pb-0 grey--text">약정업무시간</span>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" class="text-right">
                      <v-select
                        v-model="form.subtype2"
                        :items="selSubtype2"
                        dense
                        menu-props="auto"
                        style="width: 120px"
                        class="ml-8 pt-4"
                        hide-details
                        readonly
                      ></v-select>
                    </v-col>
                    <v-col cols="12" xs="12" sm="6">
                      <v-text-field
                        v-model.trim="form.str4"
                        maxlength="5"
                        suffix="h"
                        label="약정업무시간"
                        hide-details
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <!-- 특약사항 -->
            <v-col cols="12" xs="12">
              <v-textarea
                v-model="form.txt2"
                outlined
                label="특약사항"
                placeholder="입력하세요"
                no-resize
                rows="7"
                class="mt-5"
                readonly
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ clientName }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

// 구분: filters
import isNumber from '@/filters/isNumber'
import strToNumber from '@/filters/strToNumber' // '10,000' => 를 10000 로 변환
import numberFormat from '@/filters/numberFormat' // number-format
import numberFormatR from '@/filters/numberFormatR' // reverse number-format
import preventComma from '@/filters/preventComma' // keypress event 에서 쉼표막기
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat3 from '@/filters/strDateFormat3'
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      type: 25, // 유형(계약) - 중요: 개별사건계약
      depth: 2,
      isStar: 0,
      isSchedule: 0, // 계약은 일정표시 안됨
      subtype: 2, // 여기선 고문계약
      subtype2: 1, // 초기값은 월별정산 - 월별정산(1)/일괄정산(2)
      gubun1: '고문계약', // 계약구분 픽스함
      gubun2: '', // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)
      status: 1, // !! 계약상태 - 유효=1 로 픽스
      date1: '', // 수임일
      date2: '', // 계약기간(시작일)
      date3: '', // 계약기간(종료일)
      // str1: '', // 특약사항 - [2021.6.3]고문계약에서는 txt2를 사용
      str2: '0', // 수금대상(금액)
      str3: '0', // 계약금액(금액) - [2021.6.1 수정]
      str4: '0', // 약정업무시간(고문계약 전용)
      txt2: '', // 특약사항
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    teamName: '',
    teamColor: '',
    // 구분: 약정업무시간 셀렉트 - form.subtype2
    selSubtype2: [
      { text: '월별정산', value: 1 },
      { text: '일괄정산', value: 2 }
    ],
    // 구분: 연계된 의뢰인 이름 [2021.5.12]
    clientName: '',
    clientId: 0,
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str2: '수금대상' // 계약은 필수값이 없구나
      }
    },
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: ''
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  watch: {
  },

  computed: {
  },

  methods: {
    isSpKey, // 특수문자 필터
    isNumber, // 숫자만 인지 체크하는 필터
    strToNumber, // '10,000' => 10000 정수로 변환
    numberFormat,
    numberFormatR,
    preventComma,
    strDateFormat,
    strDateFormat3,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.teamName = ''
      this.teamColor = ''

      this.form.id = 0
      this.form.isStar = 0

      this.form.gubun1 = '개별사건계약' // !! 계약구분 픽스
      this.form.gubun2 = '' // !! 계약번호 - 계약에만 있다(전체계약과 비교해서 중복제거)

      // !! 일자 초기화 - clone() 으로 복사해서 써야한다!
      const curDate = this.$moment() // 오늘
      this.form.date1 = curDate.format('YYYY-MM-DD')
      this.form.date2 = curDate.format('YYYY-MM-DD')
      this.form.date3 = curDate.format('YYYY-MM-DD')

      this.form.txt2 = '' // 특약사항
      this.form.str4 = '0' // 약정업무시간(금액과 동일하게 처리)

      this.form.parentInfo = ''

      this.index = -1 // 수정용 인덱스 초기화
    },
    // !![2021.5.10] 마지막 파라미터로 의뢰인객체가 들어간다
    async open (title, options, index = -1, obj = {}, client) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // console.log(client) // open() 에서 넘어온 의뢰인 객체

        // 초기화
        await this.initDialog()

        // !![2021.5.12] 의뢰인명 매칭
        this.clientName = client.name
        this.clientId = client.id

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index

          this.form.id = obj.id
          this.form.isStar = obj.isStar
          this.form.gubun2 = obj.gubun2 // 계약번호 - !! 여기서 watch 가 발동될것임

          // 팀명과 팀컬러
          this.teamName = obj.teamName
          this.teamColor = obj.teamColor

          // 일자 처리
          const curDate = this.$moment() // 오늘
          const startDate = curDate.clone() // 수임일 초기값(오늘) - 복사
          const endDate = curDate.clone().add(1, 'years').subtract(1, 'days') // 계약기간(종료일) = 초일불산입(시작일 + 1년 - 1일)

          this.form.date1 = (obj.date1) ? obj.date1.substr(0, 10) : curDate.format('YYYY-MM-DD') // 수임일
          this.form.date2 = (obj.date2) ? obj.date2.substr(0, 10) : startDate.format('YYYY-MM-DD') // 계약기간(시작일)
          this.form.date3 = (obj.date3) ? obj.date3.substr(0, 10) : endDate.format('YYYY-MM-DD') // 계약기간(만료일)

          this.form.txt2 = obj.txt2 // 특약사항
          this.form.str4 = obj.str4 // 약정업무시간(금액과 동일하게 처리)

          this.form.subtype2 = obj.subtype2 // 약정업무시간 셀렉트값

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 확인 버튼 이벤트 핸들러 - 여기서는 뷰어의 역할만 하기 때문에 DB 처리등이 필요없다.
    async agree () {
      try {
        await this.initDialog()
        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 인명부 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      if (!(this.$router.history.current.path.indexOf('client') !== -1)) {
        this.$router.push(`/client/${this.clientId}`)
      }
    }
  }
}
</script>

<style>
.text-input-font-color__primary .v-text-field__slot input {
  color: #1976D2 !important;
}
</style>
