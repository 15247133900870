<template>
  <v-menu
    v-model="showMenu"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="options.width"
    :position-x="x"
    :position-y="y"
    absolute
    offset-y
    origin="center center"
    transition="scale-transition"
  >
    <v-card
      class="pa-3"
      max-width="650"
    >
      <v-row
        no-gutters
        align="center"
        justify="center"
      >
        <v-col cols="12" class="pa-3">
          <v-row no-gutters>
            <v-select
              v-model="form.status"
              :items="statusSelect"
              item-text="text"
              item-value="value"
              dense
              label="첨부파일구분"
              flat
              hide-details
              solo-inverted
              style="max-width: 200px;font-size: 0.785rem !important"
              @change="changeStatus"
            ></v-select>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small class="ml-2" style="cursor:pointer;">mdi-help-circle-outline</v-icon>
              </template>
              <span class="text-body-2">첨부파일유형의 추가,변경,삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
            </v-tooltip>
          </v-row>
        </v-col>
        <v-col cols="12" class="pr-5">
          <v-file-input
            ref="txtFile"
            v-model="attachFile"
            :rules="[rules.required, rules.fileCheck]"
            :show-size="1000"
            label=""
            :placeholder="uploadMsg"
            prepend-icon="mdi-paperclip"
            :loading="loadingFile"
            :disabled="loadingFile"
            @change="attachFileChange"
            autofocus
          >
            <template v-slot:selection="{ text }">
              <v-chip
                small
                label
                color="primary"
              >
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-col>
        <v-col cols="12" class="pa-3">
          <v-btn small text color="primary"
            :loading="loadingFile"
            :disabled="loadingFile"
            @click="agree"
          >
            <v-icon small class="mr-2">mdi-cloud-upload-outline</v-icon>올리기
          </v-btn>
          <v-btn small text color="error"
            @click="cancel"
          >
            <v-icon small class="mr-2">mdi-cancel</v-icon>취소
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-menu>
</template>

<script>
// * 현재 dir
const currDir = 'case'

export default {
  data: () => ({
    resolve: null, // 이 방법이 너무 참신하다!
    reject: null,
    options: {
      color: 'primary',
      width: 500
    },
    showMenu: false,
    x: 0,
    y: 0,
    // 구분: 폼
    form: {
      teamId: 1, // 기본팀이 초기값
      pId: 0, // 부모(진행내용) 아이디
      ppId: 0, // 조부모 아이디 - 소송
      ppType: 1, // 주의: 조부모 - 소송(1),자문(2)
      ppInfo: '', // 조부모 정보위치
      pType: 0, // !! 부모 유형(21, 22 - 진행내용 혹은 업무)
      pGubun1: '', // !!부모 유형명(기일, 기한...)
      status: 1, // 첨부파일구분 [2021.3.9]
      gubun2: '' // 첨부파일구분 [2021.3.9]
    },
    // 구분: 첨부파일용 변수
    attachFile: null, // 단일 첨부파일
    uploadLimit: 15, // 첨부파일 용량제한 15(MB)
    isLimitOver: false, // 첨부파일 업로드 용량을 넘는지 여부
    isCapacityOver: false, // * [2022.6.14]구글드라이브 사용가능 용량보다 큰지 여부
    availCapacity: 0, // * [2022.6.14]구글드라이브의 남은용량(사용가능량)
    loadingFile: false,
    // 구분: 첨부파일구분 셀렉트 - !! [2021.3.9]
    statusSelect: [],
    parentInfo: '' // [2021.3.24] 조부모정보 문자열(소송/자문/인명부)
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서
    rules () {
      return {
        required: value => !!value || '입력값은 필수입니다',
        fileCheck: value => !value || value.size < this.uploadLimit * 1000000 || `${this.uploadLimit}MB 이하의 파일을 선택하셔야 합니다.`
      }
    },
    uploadMsg () {
      return `파일을 선택하세요(${this.uploadLimit}MB 이하)`
    }
  },

  watch: {
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // * 중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async init () {
      try {
        this.$refs.txtFile.resetValidation()

        this.attachFile = null // 파일변수 초기화

        this.form.teamId = 1
        this.form.pId = 0
        this.form.ppId = 0
        this.form.ppType = 1
        this.form.ppInfo = ''
        // !!
        this.form.pType = 0 // !! 부모 유형
        this.form.pGubun1 = '' // !! 부모 유형명

        // !! [2021.3.9]
        this.form.status = 1 // 첨부파일구분 [2021.3.9]
        this.form.gubun2 = '' // 첨부파일구분 [2021.3.9]
        this.form.parentInfo = ''
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 메뉴 팝업을 띄우는 함수
    async show (evt) {
      try {
        if (!this.showMenu) { // !! 안전장치 - 이미 열려있으면 다시 열리지 않게 한다.
          evt.preventDefault()
          this.showMenu = false
          this.x = evt.clientX
          this.y = evt.clientY

          this.$nextTick(() => {
            this.showMenu = true
          })
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // data setting
    async setData (item) {
      try {
        if (!item) throw new Error(`잘못된 인자 형식입니다.`)

        // 주의: 폼값 세팅 : 나머지 정보를 입력한다(부모와 조부모의 정보)
        // 주의: 넘어온 item.id 가 pId로 입력되는 것을 주의!
        this.form.teamId = item.teamId // 팀아이디
        this.form.pId = item.id // 부모(진행내용) 아이디
        this.form.ppId = item.pId // 조부모 아이디
        this.form.ppType = item.pType // 조부모 타입 - 소송(1),자문(2)

        // this.form.ppInfo = item.pInfo // 조부모 정보위치
        // 중요:[2021.3.22] 조부모의 정보를 문자열로 변환해서 넘긴다.
        // this.form.ppInfo = JSON.stringify(item.pInfo) 이미 문자열로 넘어와서 문자열변환이 불필요함
        this.form.ppInfo = item.pInfo

        // 중요:[2021.3.24] 소송/자문/인명부처럼 조부모의 데이터를 파싱해야 할 경우
        // 댓글,첨부파일,공유링크의 경우 parentInfo 는 사실 조부모의 정보임
        const parsedPinfo = JSON.parse(item.pInfo)
        if (item.pType === 1) { // 소송
          this.form.parentInfo = parsedPinfo.caseNum
        } else { // 자문
          this.form.parentInfo = parsedPinfo.manageNum
        }

        // !! 추가
        this.form.pType = item.oType // !! 부모의 유형(여기서는 oType 을 보낸다)
        this.form.pGubun1 = item.gubun1 // !! 부모 유형명

        // !! [2021.3.9 추가] 카테고리 셀렉트
        this.setCates('24').then(({ cates }) => {
          if (cates && cates.length > 0) {
            this.statusSelect.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
            // 관리상태의 초기값 지정
            this.form.status = this.statusSelect[0].value
            this.changeStatus()
          }
        })

        // ! [2022.6.14 추가] 구글드라이브 용량정보 패칭 - 실시간으로 남은 용량을 알아보자
        const { data } = await this.$axios.get(`lawork/${currDir}/getGoogleDriveQuota`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.gdQuoat) {
          // * 구글 드라이브의 사용할 수 있는 용량 = limit - usage
          this.availCapacity = parseInt(data.gdQuoat.limit, 10) - parseInt(data.gdQuoat.usage, 10)
          // console.log(`this.availCapacity: ${this.availCapacity} Bytes`)
        }

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 등록 - 등록과 동시에 구글로 전송시킨다. 수정은 없다
    async agree () {
      try {
        // * txtFile 파일폼에 에러가 발생한 경우
        if (!this.$refs.txtFile.validate()) {
          this.$refs.txtFile.focus()

          // # 용량이 넘는 경우도 여기로 올 수 있다.
          if (this.isLimitOver) {
            // 팝업 에러창
            const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
            if (!pop) throw new Error('팝업창을 열 수 없습니다.')
            // 찾았으면 팝업을 연다
            const msg = '업로드 가능한 파일용량이 초과되었습니다<br><strong>15MB이하의 파일</strong>만 가능합니다'
            if (await pop.open('용량초과', msg, { color: 'error', width: 400 })) {
              this.attachFile = null
              this.isLimitOver = false
              throw new Error('입력값을 확인하세요.')
            }
          }
          throw new Error(`입력값을 확인하세요.`)
        }

        // * 업로드 용량이 넘는 경우 경고
        if (this.isLimitOver) {
          this.attachFile = null
          throw new Error(this.uploadMsg)
        }

        // # [2022.6.14]구글드라이브 사용가능 용량이 넘는 경우 경고
        if (this.isCapacityOver) {
          // 팝업 에러창
          const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `구글 드라이브의 사용가능한 용량을 초과했습니다.<br><strong>관리자계정의 구글드라이브</strong>를 확인하시기 바랍니다.`
          if (await pop.open('드라이브 용량초과', msg, { color: 'error', width: 400 })) {
            this.attachFile = null
            this.isCapacityOver = false
            throw new Error(`입력값을 확인하세요.`)
          }
        }

        // 파일을 전송할 FormData 객체 생성
        let formData = new FormData()

        if (this.attachFile) { // 단일 파일로 변경
          formData.append('attachFile', this.attachFile, this.attachFile.name)

          // 주의: 파일폼 처리
          formData.append('teamId', this.form.teamId) // 팀아이디
          formData.append('pId', this.form.pId) // 부모(진행내용) 아이디
          formData.append('ppId', this.form.ppId) // 조부모 아이디
          formData.append('ppType', this.form.ppType) // 조부모 타입
          formData.append('ppInfo', this.form.ppInfo) // 조부모정보 객체문자열
          formData.append('parentInfo', this.form.parentInfo) // 조부모정보 간단문자열
          // !! 추가
          formData.append('pType', this.form.pType) // 부모 유형
          formData.append('pGubun1', this.form.pGubun1) // 부모 유형명

          // !![2021.3.9] 추가
          formData.append('status', this.form.status) // 첨부파일구분
          formData.append('gubun2', this.form.gubun2) // 첨부파일루분

          // 전송전에 로딩걸기
          this.loadingFile = true

          // 리턴객체
          let resolve = {
            isDeleted: false, // 조부모가 삭제되었는지 여부
            isDelParent: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }

          // 데이터 전송
          const { data } = await this.$axios.post(`lawork/${currDir}/uploadSimple`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.isDelParent = data.isDelParent
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'success'

          this.loadingFile = false // 로딩 제거

          await this.init()
          this.resolve(resolve) // this.resolve(true)
          this.showMenu = false
        } else {
          throw new Error(`업로드 할 파일을 선택하십시오`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    async cancel () {
      try {
        if (!this.loadingFile) { // 업로드 중엔 닫지 않게
          await this.init()
          this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
          this.showMenu = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 영역의 change event handler
    async attachFileChange () {
      try {
        if (this.attachFile) { // 첨부파일이 들어온 경우만
          // # 업로드 제한 용량을 넘는가? 15MB = 15728640Bytes 이지만 15000000 으로 맞춰준다.
          // const upLimit = this.uploadLimit * (1024 * 1024 = 1048576)
          const upLimit = this.uploadLimit * 1000000
          if (this.attachFile.size > upLimit) {
            // * 제한용량이 넘은 경우
            this.isLimitOver = true
          } else {
            // * 제한용량이 안넘은 경우
            this.isLimitOver = false

            // # [2022.6.17]구글드라이브의 사용가능한 용량보다 크면 안된다.
            if (this.attachFile.size > this.availCapacity) {
              // * 구글 드라이브 사용가능용량보다 크다
              this.isCapacityOver = true
            } else {
              this.isCapacityOver = false
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 카테고리 셀렉트 변경 이벤트 핸들러
    async changeStatus () {
      try {
        // this.form.gubun2 에 카테고리 문자열 값을 매칭한다
        this.form.gubun2 = this.statusSelect.find(s => s.value === this.form.status).text
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: [util함수] 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
