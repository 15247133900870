<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        v-if="!isAutoInput"
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1 && !isAutoInput"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-btn
        slot="star-area"
        icon
        @click.native="form.isStar = !form.isStar"
      >
        <v-icon
          v-if="!form.isStar"
          color="grey lighten-1"
        >
          mdi-star-outline
        </v-icon>
        <v-icon
          v-else
          color="yellow"
        >
          mdi-star
        </v-icon>
      </v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <!-- <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto py-2 px-3"
        :max-height="options.height"
      >
        <v-card-text> -->

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <v-card-text
        slot="content"
        class="pb-0"
      >
        <v-container fluid class="pb-0"><!-- 여기까지 적용 -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="article.teamName"
                label="담당부서"
                readonly
              >
                <template v-slot:prepend>
                  <v-icon
                    :color="article.teamColorOrigin"
                  >mdi-account-group</v-icon>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" xs="12">
              <v-text-field
                v-model.trim="form.str1"
                maxlength="20"
                label="기한명"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" xs="12" sm="3">
              <v-menu
                ref="date1Selector"
                v-model="date1Selector"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model.trim="form.date1"
                    label="일자"
                    prepend-icon="event"
                    readonly
                    v-on="on"
                    style="width: 200px;"
                  ></v-text-field>
                </template>
                <v-date-picker
                  ref="picker"
                  v-model="form.date1"
                  locale="ko"
                  @change="saveDate1"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" xs="12" sm="1"  class="text-left">
              <!-- 기한자동계산 팝업 -->
              <v-dialog
                v-model="calcPop"
                width="450"
              >
                <template v-slot:activator="{ on }">
                  <v-btn fab text small dark color="primary"
                    v-on="on"
                    class="mt-3"
                  >
                    <v-icon>mdi-calculator</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark color="primary" dense flat>
                    <v-toolbar-items>
                      <v-btn dark text class="orange--text text-h6 font-weight-bold"
                        @click.native="calcDeadlineDate"
                      >등록</v-btn>
                      <v-btn dark text class="white--text subheading"
                        @click.native="calcPop = false"
                      >취소</v-btn>
                    </v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="white--text">기한자동계산</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12" xs="12" sm="6">
                        <v-menu
                          ref="deadlineSelector"
                          v-model="deadlineSelector"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model.trim="deadlineDate"
                              label="기준일"
                              prepend-icon="event"
                              readonly
                              v-on="on"
                              style="width: 200px;"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker2"
                            v-model="deadlineDate"
                            locale="ko"
                            @change="saveDeadlineDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" xs="12" sm="4">
                        <div style="width: 140px;">
                          <v-select
                            v-model="calcOpt1"
                            :items="calcSelect1"
                            label=""
                            item-value="value"
                            item-text="text"
                            hide-details
                            class="mt-4 mx-3"
                            dense
                            style="font-size: 0.785rem !important"
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" sm="2">
                        <v-tooltip bottom class="ml-3" color="primary">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" small class="ml-2 mt-6" color="primary" style="cursor:pointer;">mdi-help-circle</v-icon>
                          </template>
                          <span class="text-body-2">공시송달 (0시 도달), 송달간주 (전자소송) 등에는 초일산입을 선택해야 합니다.</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="12" xs="12">
                        <div style="width: 180px;">
                          <v-select
                            v-model="calcOpt2"
                            :items="calcSelect2"
                            label="적용기간"
                            item-value="value"
                            item-text="text"
                            hide-details
                            class="mt-3 mr-3"
                            dense
                            style="font-size: 0.785rem !important"
                          ></v-select>
                        </div>
                      </v-col>
                      <v-col cols="12" xs="12" class="mt-0 pt-0">
                        <v-checkbox
                          v-model="applyHolyday"
                          small
                          dense
                          label="공휴일,대체휴일의 익일계산 반영"
                        ></v-checkbox>
                        <!-- <v-icon
                          small
                          class="mr-0"
                          @click="applyHolyday = !applyHolyday"
                        >{{ applyHolyday ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                        공휴일,대체휴일의 익일계산 반영
                         -->
                      </v-col>
                      <v-col cols="12" xs="12">
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <!--// 기한자동계산 팝업 -->
            </v-col>
            <v-col cols="12" xs="12" sm="8" class="text-left">
              <div>&nbsp;</div>
            </v-col>
            <v-col cols="12" xs="12" class="mt-3">
              <v-alert
                border="left"
                outlined
                type="info"
                dense
                class="text-body-2"
              >
                {{ article.manageNum }}
                <v-tooltip color="info" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      small color="primary lighten-2"
                      @click="gotoDetail"
                      class="ml-1"
                    >mdi-launch</v-icon>
                  </template>
                  <span>바로가기</span>
                </v-tooltip>
              </v-alert>
            </v-col>
            <!-- <v-col cols="12" v-show="index > -1" class="py-0">
              <v-card
                tile
                elevation="0"
                class="ma-0 pa-0"
              >
                <v-card-actions class="ma-0 pa-0">
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>print</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>get_app</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>phone_iphone</v-icon>
                  </v-btn>
                  <v-btn small text icon color="primary" disabled>
                    <v-icon small>local_post_office</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <span class="text-caption mr-2">{{ viewWname }}</span><span class="text-caption">{{ viewUpdatedAt }}</span>
                </v-card-actions>
              </v-card>
            </v-col> -->
            <v-col cols="12">
              <div class="mb-8"></div>
            </v-col>

          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        </v-container>
      </v-card-text>

        <!-- 상단 타이틀 고정시 사용 -->
        <!-- </v-card-text>
      </v-card> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import strDateFormat from '@/filters/strDateFormat'
import holyday from '@/lib/holyday'

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 770,
      height: 750,
      zIndex: 200
    },
    // 구분: Form 관련
    form: {
      id: 0,
      teamId: 1, // 기본팀이 초기값
      type: 21, // 중요: 유형 - 진행내용
      depth: 2,
      pId: 0,
      pType: 2, // 중요: 부모타입 = 자문
      pInfo: '', // 정보위치(부모의 정보 중 보여줄 정보)
      isStar: 0,
      isSchedule: 1,
      subtype: 2, // 중요: 기본값은 2(기한)
      gubun1: '', // 기한 타이틀
      status: 1,
      date1: '', // 업무일 = 기한일
      str1: '자문마감기한', // 기한명
      parentInfo: '' // [2021.3.23] 부모정보 문자열
    },
    // !!구분: 팝업을 열면서 넘어온 subtype 을 매칭시켜야 한다!
    subType: 2, // 기한전용이므로 안전하게 기한으로 매칭
    // 구분: 업무유형 - 카테고리
    upmuCates: [],
    // 구분:
    index: -1, // !! 수정 인덱스
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        str1: '기한명 '
      }
    },
    // 구분: 입력 검증
    rules: {
      txtCounter: v => v.length <= 200 || '200자 이하의 내용을 입력해 주세요'
    },
    // 구분: 일자 - 시간
    date1Selector: false, // 일자 셀렉터
    // 구분: 수정시 처리자와 최종업데이트 정보
    viewWname: '',
    viewUpdatedAt: '',
    // 구분: 자동입력 데이터인 경우
    isAutoInput: false,
    // 구분: 기한 자동계산 관련
    calcPop: false, // 기한자동계산 팝업
    deadlineSelector: false, // 기한자동계산 기준일 셀렉터
    deadlineDate: '', // 기한자동계산 기준일
    calcOpt1: 1, // 초일불산입/초일산입 초기값
    calcOpt2: '2w', // 적용기간 초기값
    calcSelect1: [
      { text: '초일불산입', value: 1 },
      { text: '초일산입', value: 2 }
    ],
    calcSelect2: [
      { text: '14일(2주)', value: '2w' },
      { text: '7일(1주)', value: '1w' },
      { text: '3일', value: '3d' },
      { text: '10일', value: '10d' },
      { text: '20일', value: '20d' },
      { text: '30일', value: '30d' },
      { text: '1개월', value: '1M' },
      { text: '60일', value: '60d' },
      { text: '2개월', value: '2M' },
      { text: '90일', value: '90d' },
      { text: '3개월', value: '3M' },
      { text: '180일', value: '180d' },
      { text: '6개월', value: '6M' },
      { text: '1년', value: '1y' },
      { text: '3년', value: '3y' },
      { text: '5년', value: '5y' }
    ],
    applyHolyday: true // 토,일,공휴일의 익일계산 반영여부
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    strDateFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      this.form.id = 0
      this.form.teamId = 1 // 기본팀을 기본값으로
      this.form.subtype = this.subType // 중요: 넘어온 subtype 을 매칭한다!
      this.form.pId = 0 // 부모 아이디
      this.form.pType = 2 // 중요: 부모 타입 = 자문
      this.form.pInfo = '' // 정보위치(부모의 정보 중 보여줄 정보)
      this.form.gubun1 = this.title // !! 넘어온 상단의 제목이 gubun1 이 된다!
      // this.form.gubun2 = '' // 기일 - 예정
      this.form.status = 1
      this.form.isStar = 0
      this.form.isSchedule = 1 // 일정표시여부
      this.form.date1 = this.$moment().format('YYYY-MM-DD') // 기한일시 초기값(오늘)
      this.form.str1 = '자문마감기한' // 기한명
      this.form.parentInfo = ''

      this.isAutoInput = false

      this.calcOpt1 = 1
      this.calcOpt2 = '2w'

      // 기한자동계산 기준일 초기값
      this.deadlineDate = this.form.date1

      this.index = -1 // 수정용 인덱스 초기화
    },
    // 중요: 소송의 기한과 다르게 자문에서는 songdal 객체가 파라미터로 넘어오지 않는다.
    async open (title, options, subtype, index = -1, obj = {}) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 subtype 에 따라 기일/기한이 결정되므로 중요하다
        this.subType = subtype

        // console.log(this.article) // !! props 로 넘어온 부모 데이터

        // 초기화
        await this.initDialog()

        // !! 아래 3개는 부모로부터 넘겨받은 데이터로 매칭하자(뭐 이렇게 하지 않아도 되는데)
        this.form.teamId = this.article.teamId
        this.form.pId = this.article.id

        this.form.parentInfo = this.article.manageNum

        // 중요:[2021.3.22 수정] 부모정보의 객체를 문자열로 변환해서 this.form.pInfo 에 담는다.
        this.form.pInfo = JSON.stringify(this.article.pInfo)

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          // console.log(obj) // 파라미터로 넘어온 업무
          this.index = index

          this.form.id = obj.id

          this.form.gubun1 = obj.gubun1 // 진행내용 타입 문자열 - 가일/기한...
          // this.form.gubun2 = obj.gubun2 // 기일 - 예정
          this.form.status = obj.status
          this.form.subtype = obj.subtype // !! 이미 초기화에서 적용했으므로 의미는 없는데?
          this.form.isStar = obj.isStar

          // !! 진행내용에선 의미 없지만 일단 놔둔다.
          // 등록과 같은 자동입력 데이터는 유형을 재조정 - 유형재조정은 포기
          if (obj.subtype === 0) {
            this.isAutoInput = true // 자동입력 데이터임을 표시
          }

          // 일자 처리(기한은 시간을 선택하지 않는다)
          this.form.date1 = obj.date1.substr(0, 10)

          // 중요: 기한자동계산 기준일을 기한일자로 맞춘다
          this.deadlineDate = this.form.date1

          // 참고: 편집일때는 처리자와 업데이트 일자를 보여준다.
          this.viewWname = obj.wname
          this.viewUpdatedAt = strDateFormat(obj.updatedAt) // 필터로 쓰진 않고 함수로 사용
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 등록
    async agree () {
      try {
        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // console.log(this.form)
        // !![2021.4.8] 기한 업무일시 문자열 만들기 - 00:40:01 로 한다.
        this.form.date1 = `${this.form.date1.substr(0, 10)} 00:40:01`

        // !! 돌려주는 객체
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러가 있는 경우 채워지는 객체
          rsMsg: '' // resolve 메시지
        }

        // !! DB 입력 처리 (등록/수정 동일하게 처리)
        const { data } = await this.$axios.post(`lawork/advice/setUpmuContent`, this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        // 리턴값으로 resolve 채우기
        resolve.isDeleted = data.isDeleted
        resolve.teamError = data.teamError
        resolve.chInfo = data.chInfo
        resolve.rsMsg = 'edited'

        // 등록 후
        await this.initDialog()
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소
    async cancel () {
      try {
        await this.initDialog() // 초기화
        this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 삭제
    async remove () {
      try {
        if (!this.form.id) throw new Error(`잘못된 요청입니다.`)

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>삭제된 데이터는 복구되지 않습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // !! 돌려주는 객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post(`lawork/advice/deleteUpmuContent`, this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed' // 삭제됨

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 일자 셀렉트 선택시 저장 함수
    saveDate1 (date) {
      // !! 아래를 주석처리하면 팝업창이 닫히지 않는다!
      this.$refs.date1Selector.save(date)

      // 중요: 기한자동계산 기준일도 같이 저장
      this.deadlineDate = date
    },
    // !! 기한자동계산 기준일 셀렉트 선택시 저장 함수
    saveDeadlineDate (date) {
      this.$refs.deadlineSelector.save(date)
    },
    // !! 기한자동계산 실행함수
    /*
    deadlineDate: '', // 기한자동계산 기준일
    calcOpt1: 1, // 초일불산입/초일산입 초기값
    calcOpt2: '2w', // 적용기간 초기값
    */
    async calcDeadlineDate () {
      try {
        // console.log(this.deadlineDate)
        // console.log(this.calcOpt1)
        // console.log(this.calcOpt2)
        // 기준일이 없으면 에러
        if (!this.deadlineDate || this.deadlineDate.length <= 0) {
          throw new Error(`기준일을 입력하셔야 합니다.`)
        }

        let limitDate = this.deadlineDate // 계산된 기한일의 초기값을 기준일로 일단 넣자.

        // 주의: 모멘트에서 월은 'M'이다 'm'은 minutes로 분의 의미이니 주의할것
        const dayNum = parseInt(this.calcOpt2, 10) // 적용기간에서 정수만 가져온다
        const dayStr = this.calcOpt2.substr(-1) // 적용기간에서 맨 마지막 글자를 가져온다.
        // console.log(`${dayNum}, ${dayStr}`) // '14, d' 와 같은 형태를 가진다.

        // 기한일을 구한다.
        limitDate = this.$moment(limitDate).add(dayNum, dayStr).format('YYYY-MM-DD')

        // !! 월/년의 경우는 기준일이 말일인지 여부를 따져야 한다!
        // !! 초일산입의 경우는 이 경우도 무조건 -1일을 하면 되나? - 현재 로데스크도 이렇게 하고 있다
        if (dayStr === 'M' || dayStr === 'y') {
          const endOfMonth = this.$moment(this.deadlineDate).endOf('month').format('YYYY-MM-DD') // 기준일의 말일을 구하자
          if (endOfMonth === this.deadlineDate) { // 기준일이 말일이다
            // 기준일이 말일인 경우는 기한일도 말일로 맞추면 된다.
            limitDate = this.$moment(limitDate).endOf('month').format('YYYY-MM-DD')
          }
        }

        // 초일산입인 경우 -1 일
        if (this.calcOpt1 === 2) {
          limitDate = this.$moment(limitDate).subtract(1, 'days').format('YYYY-MM-DD')
        }

        // 토.일.공휴일.대체휴일 계산
        // 고쳐: 여기에 함수로 처리
        if (this.applyHolyday) { // 공휴일,대체휴일의 익일계산반영인 경우 - ?? 여기에 토.일도 들어가는거 맞겠지?
          // 토.일.공휴일 계산과 적용
          limitDate = this.calcWeekendHolyday(limitDate)
        }

        // 초일산입과 공휴일 계산까지 끝낸 후의 최종 데이터
        // console.log('>> ', limitDate)
        this.form.date1 = limitDate

        // 자동계산 팝업창 닫기
        this.calcPop = false
        // 변수 초기화 -- 이게 꼭 좋다고만 할 수는 없겠다
        this.deadlineDate = this.form.date1
        this.calcOpt1 = 1
        this.calcOpt2 = '2w'
        this.applyHolyday = true
        //
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 토.일요일 여부 리턴
    isWeekend (date) {
      try {
        // console.log(this.$moment(limitDate).day()) // 일 ~ 토 => 0 ~ 6
        // console.log(this.$moment.weekdays(this.$moment(limitDate).day())) // 한글로 일 ~ 토가 나옴
        const yoilNum = this.$moment(date).day()
        return (yoilNum === 0 || yoilNum === 6)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 법정공휴일 여부 리턴
    isHolyday (date) {
      try {
        // console.log(`법정 공휴일: ${holyday[date]}`)
        // 찾으면 공휴일명을 리턴, 찾지못하면 undefined 를 리턴
        return holyday[date]
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 토.일.공휴일인 경우 1일씩 더한 날짜를 리턴한다.
    calcWeekendHolyday (date) {
      try {
        let returnDate = date
        if (this.isWeekend(returnDate) || this.isHolyday(returnDate)) {
          // 토.일.공휴일에 1일을 더한다.
          returnDate = this.$moment(returnDate).add(1, 'd').format('YYYY-MM-DD')
          // 1일을 더한 날짜를 재귀용법으로 다시 계산한다.
          returnDate = this.calcWeekendHolyday(returnDate)
        }
        return returnDate
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 상세페이지 바로가기
    gotoDetail () {
      this.dialog = false
      // !! 현재 경로가 advice 디렉토리가 아닌 경우만 advice/ 로 이동시킨다
      if (!(this.$router.history.current.path.indexOf('advice') !== -1)) {
        this.$router.push(`/advice/${this.article.id}`)
      }
    }
  }
}
</script>
