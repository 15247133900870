<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <baseModal>

      <v-btn
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">해제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <!-- 중요: 상단 툴바 타이틀을 고정시키기 위해 아래처럼 v-card에 max-height를 주면 된다 -->
      <v-card
        slot="content"
        tile
        elevation="0"
        class="overflow-y-auto ma-0 pa-0"
        :min-height="options.height"
        :max-height="options.height + 150"
      >
        <v-card-text class="ma-0 pa-0">

      <!-- 상단 툴바를 고정시키지 않으려면 아래 주석을 푼다. 하단의 태그 끝에도 주석을 풀어야 한다. -->
      <!-- 다른 팝업과 다르게 class="ma-0 pa-0" 으로 맞춰야 한다 -->
      <!-- <v-card-text
        slot="content"
        class="ma-0 pa-0"
      >
        <v-container fluid class="ma-0 pa-0"> -->

          <v-row no-gutters>
            <v-col cols="12" xs="12">
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col cols="12" class="ma-0 pa-0">
                  <v-toolbar dense flat style="height: 40px;">
                    <v-toolbar-title class="text-subtitle-2 font-weight-bold">
                      <v-icon small left>mdi-format-list-bulleted-square</v-icon>
                      {{ subTitle2 }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <!-- <v-icon small left>mdi-format-list-bulleted-square</v-icon>
                  <span class="text-subtitle-2 font-weight-bold" style="font-weight: 600;">{{ subTitle2 }}</span> -->
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                  <v-card
                    elevation="0"
                    outlined
                    min-height="280"
                    class="ma-2 pa-0"
                  >
                    <v-card-text class="text-body-2 text--primary pa-2">
                      <v-card-subtitle class="px-1 py-1">
                        <v-icon small left>mdi-account</v-icon>기본정보
                      </v-card-subtitle>
                      <v-icon small left
                        v-show="cInfo.isStar"
                        color="amber darken-1"
                        class="mb-1 ml-2"
                      >mdi-star</v-icon>
                      <span
                        v-show="cInfo.name"
                        :class="!cInfo.isStar ? 'ml-2' : ''"
                        class="text-subtitle-1 font-weight-bold blue--text text--darken-2"
                      >{{ cInfo.name }}</span>
                      <!-- <v-icon small
                        v-show="cInfo.id"
                        color="primary"
                        @click="goClientDetail(cInfo.id)"
                        class="mb-1 mx-1"
                      >mdi-launch</v-icon> -->
                      <div
                        v-show="cInfo.mgroupStr || cInfo.teamName"
                        class="my-0 py-0"
                      >
                        <span class="mx-2">{{ cInfo.mgroupStr }}</span>
                        <span :class="`${article.teamColor}`">
                          {{ cInfo.teamName ? `[${cInfo.teamName}]` : '' }}
                        </span>
                      </div>
                      <div
                        v-show="cInfo.national || cInfo.address"
                        class="pl-2 pb-1"
                      >
                        {{ cInfo.national ? `(${cInfo.national}) ` : '' }}{{ cInfo.address ? cInfo.address : '' }}
                      </div>
                      <div
                        v-show="cInfo.hp || cInfo.tel"
                        class="pl-2 pb-1"
                      >
                        <span v-show="cInfo.hp" class="mr-1 grey--text">M</span>
                        <span v-show="cInfo.hp" class="mr-5">{{ cInfo.hp }}</span>
                        <span v-show="cInfo.tel" class="mr-1 text-body-2 grey--text">T</span>
                        <span v-show="cInfo.tel" class="text-body-2">{{ cInfo.tel }}</span>
                      </div>
                      <div
                        v-show="cInfo.fax || cInfo.email"
                        class="pl-2 pb-1"
                      >
                        <span v-show="cInfo.fax" class="mr-1 grey--text">F</span>
                        <span v-show="cInfo.fax" class="mr-5">{{ cInfo.fax }}</span>
                        <span v-show="cInfo.email" class="mr-1 text-body-2 grey--text">E</span>
                        <span v-show="cInfo.email" class="text-body-2">
                          {{ cInfo.email }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                v-show="cInfo.email"
                                v-on="on"
                                small
                                class="ml-1"
                                @click="writeEmail(cInfo.email)"
                              >mdi-email-outline</v-icon>
                            </template>
                            <span>메일보내기</span>
                          </v-tooltip>
                        </span>
                      </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text class="text-body-2 text--primary pa-2">
                      <v-card-subtitle class="px-1 py-1">
                        <v-icon small left>mdi-account-supervisor</v-icon>관계인 ({{ cInfo.relPersons.length }})
                      </v-card-subtitle>
                      <template v-for="(item, i) in cInfo.relPersons">
                        <v-row
                          :key="i"
                          no-gutters
                        >
                          <v-col cols="12" xs="12" class="mb-2">
                            <v-card
                              :elevation="2"
                              class="pa-2"
                              min-height="80"
                            >
                              <div class="py-1">
                                <span class="font-weight-bold mr-2">{{ item.str1 }}</span>
                                <span class="font-weight-regular">{{ item.str2 ? `[${item.str2}]` : '' }}</span>
                              </div>
                              <div>
                                <span v-show="item.str3" class="mr-1 grey--text">M</span>
                                <span v-show="item.str3" class="mr-5">{{ item.str3 }}</span>
                                <span v-show="item.str4" class="mr-1 text-body-2 grey--text">T</span>
                                <span v-show="item.str4" class="text-body-2">{{ item.str4 }}</span>
                              </div>
                              <div>
                                <span v-show="item.str5" class="mr-1 grey--text">F</span>
                                <span v-show="item.str5" class="mr-5">{{ item.str5 }}</span>
                                <span v-show="item.str6" class="mr-1 text-body-2 grey--text">E</span>
                                <span v-show="item.str6" class="text-body-2">
                                  {{ item.str6 }}
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-icon
                                        v-show="item.str6"
                                        v-on="on"
                                        small
                                        class="ml-1"
                                        @click="writeEmail(item.str6)"
                                      >mdi-email-outline</v-icon>
                                    </template>
                                    <span>메일보내기</span>
                                  </v-tooltip>
                                </span>
                              </div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </template>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        <!-- 상단 타이틀 고정을 풀때 사용 -->
        <!-- </v-container>
      </v-card-text> -->

        <!-- 상단 타이틀 고정시 사용 -->
        </v-card-text>
      </v-card>

    </baseModal>

  </v-dialog>
</template>

<script>
import ko from 'vee-validate/dist/locale/ko'
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그

export default {
  components: {
    baseModal
  },

  props: [ 'article' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    // 구분: 다이얼로그 관련
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 960,
      height: 700,
      zIndex: 200
    },
    // 구분: 연계의뢰인 등록 폼
    form: {
      id: 0,
      teamId: 0, // 팀 아이디
      type: 0,
      depth: 0,
      pId: 0,
      pType: 0,
      rId: 0,
      rType: 0,
      subtype: 0,
      caseId: 0, // 소송 아이디
      caseType: 1, // 소송/자문 타입
      caseInfo: null, // 소송 정보
      clientId: 0, // 의뢰인/상대방 아이디
      clientType: 1, // 의뢰인/상대방 타입(의뢰인 - 1, 상대방 - 2)
      clientInfo: null // 의뢰인 정보
    },
    // !!구분: 연계 의뢰인 타입(32)
    lwcType: 32,
    // !!구분: 팝업을 열면서 넘어온 subtype 을 매칭시켜야 한다!
    subType: 1,
    // 구분: _veeValidate dictionary
    dictionary: {
      messages: ko.messages,
      attributes: {
        // str1: '기일명'
      }
    },
    // 구분: 의뢰인/관계인
    clTypes: [
      { text: '의뢰인', value: 'name' },
      { text: '관계인', value: 'keyword' }
    ],
    // 구분: 선택한 의뢰인 정보
    cInfo: {
      id: 0,
      teamId: 1,
      mgroupStr: '',
      teamColor: '',
      teamName: '',
      name: '',
      national: '',
      isStar: 0,
      address: '',
      hp: '',
      tel: '',
      fax: '',
      email: '',
      relPersons: []
    },
    subTitle1: '연계의뢰인',
    subTitle2: '의뢰인'
  }),

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화
    async initDialog () {
      // validation 초기화
      this.$validator.reset()

      // 연계 의뢰인 등록폼 초기화
      this.form.id = 0
      this.form.teamId = 0 // 팀 아이디
      this.form.type = 0
      this.form.depth = 0
      this.form.pId = 0
      this.form.pType = 0
      this.form.rId = 0
      this.form.rType = 0
      this.form.subtype = 0
      this.form.caseId = 0 // 소송 아이디
      this.form.caseType = 1 // 소송/자문 타입 (소송 = 1, 자문 = 2)
      this.form.caseInfo = null // 소송 정보
      this.form.clientId = 0 // 의뢰인 아이디
      this.form.clientType = 1 // 의뢰인/상대방 타입(의뢰인 = 1, 상대방 = 2)
      this.form.clientInfo = null // 의뢰인 정보

      // 선택 의뢰인/상대방 정보 초기화
      await this.initCInfo()
    },
    // 선택 의뢰인/상대방 정보 초기화
    async initCInfo () {
      this.cInfo.id = 0
      this.cInfo.teamId = 1
      this.cInfo.mgroupStr = ''
      this.cInfo.teamColor = ''
      this.cInfo.teamName = ''
      this.cInfo.name = ''
      this.cInfo.national = ''
      this.cInfo.isStar = ''
      this.cInfo.address = ''
      this.cInfo.hp = ''
      this.cInfo.tel = ''
      this.cInfo.fax = ''
      this.cInfo.email = ''
      this.cInfo.relPersons = []
    },
    // !! 제목, 옵션, subtype=[1 의뢰인 / 2 상대방], 선택한 연계의뢰인 객체
    // !! this.article 에는 부모인 소송의 정보가 담겨옫나.
    async open (title, options, subtype, lwcommon) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        // 중요: 파라미터로 넘어온 subtype 에 따라 주체유형(의뢰인/상대방)이 결정되므로 중요하다.
        // subtype 은 clients.type 에 매칭한다
        this.subType = subtype

        // console.log(this.article) // !! props 로 넘어온 부모(소송) 데이터
        // console.log(lwcommon) // !! 인자로 넘어온 연계인명부(type=32)

        // 초기화
        await this.initDialog()

        // 의뢰인/상대방에 맞춰 타이틀 변환
        if (this.subType === 1) {
          this.subTitle1 = '연계의뢰인'
          this.subTitle2 = '의뢰인'
          this.clTypes[0].text = '의뢰인'
        } else {
          this.subTitle1 = '연계상대방'
          this.subTitle2 = '상대방'
          this.clTypes[0].text = '상대방'
        }

        // !! this.form 값 만들기
        this.form.id = lwcommon.id
        this.form.clientId = lwcommon.rId

        this.form.type = lwcommon.type
        this.form.depth = lwcommon.depth
        this.form.pId = lwcommon.pId
        this.form.pType = lwcommon.pType
        this.form.rId = lwcommon.rId
        this.form.rType = lwcommon.rType
        this.form.subtype = lwcommon.subtype

        // 인명부 정보를 만든다
        this.form.clientInfo = {
          name: lwcommon.rInfo.name,
          hp: lwcommon.rInfo.hp,
          tel: lwcommon.rInfo.tel,
          fax: lwcommon.rInfo.fax,
          email: lwcommon.rInfo.email
        }

        // 연계의뢰인 등록/수정 폼 - 팀과 소송의 정보는 넘어온 부모정보로 픽스된다.
        this.form.teamId = this.article.teamId // 팀 아이디
        this.form.caseId = this.article.id // 소송 아이디
        this.form.caseType = this.article.type1 // 소송 = 1, 자문 = 2 : cases.type1 과 매칭
        this.form.clientType = this.subType // 의뢰인/상대방 타입: 의뢰인 = 1, 상대방 = 2

        // 소송/자문에 따라 보여줄 정보 만들기
        let viewInfo = ''
        if (this.article.type1 === 1) {
          // 소송인 경우
          viewInfo = `${this.article.caseNum}${this.article.manageNum ? `/${this.article.manageNum}` : ''}`
        } else {
          // 자문인 경우
          viewInfo = `${this.article.manageNum}`
        }

        // 소송/자문 정보 매칭
        this.form.caseInfo = {
          viewInfo, // 보여줄 정보
          gubun1: this.article.gubun1,
          gubun4: this.article.gubun4,
          gubun5: this.article.gubun5,
          courtName: this.article.courtName,
          caseNum: this.article.caseNum,
          caseName: this.article.caseName,
          manageNum: this.article.manageNum,
          clType: this.article.clType,
          opType: this.article.opType,
          clName: this.article.clName,
          opName: this.article.opName,
          jepanbu: this.article.jepanbu,
          status: this.article.status,
          status2: this.article.status2
        }

        // !! 연계 인명부 정보 패칭해서 cInfo 에 매칭하기
        // 기존에는 쿼리해서 가져왔지만 lwcommon.rInfo 를 이용하면 된다.
        // this.cInfo = lwcommon.rInfo
        // item.rId 로 연계 인명부 정보 패칭해서 cInfo 에 매칭하기
        const params = {
          id: lwcommon.rId
        }
        const { data } = await this.$axios.get(`lawork/client/getClientWhere`, { params })
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        if (data.article) {
          this.cInfo = data.article
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 선택한 의뢰인/상대방 상세화면으로 가기
    async goClientDetail (cId) {
      try {
        this.$router.push(`/client/${cId}`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 연계 의뢰인/상대방 해제
    // 주의: 같이 등록한 연계 사건도 같이 삭제된다!
    async remove () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = this.form.clientType === 1 ? '해제시 개별사건계약은 자동삭제되며,<br>동 사건의 비용, 타임시트 정보를 의뢰인 페이지에서 볼 수 없습니다.' : '상대방 정보와의 연결을 해제합니다'
        if (await pop.open('해제', msg, { color: 'error', width: 420 })) {
          // 리턴객체
          let resolve = {
            isDeleted: false, // 부모가 삭제되었는지 여부
            teamError: false, // 부모의 팀에러
            chInfo: null, // 부모의 에러에 대한 메시지
            rsMsg: '' // resolve 메시지
          }
          const { data } = await this.$axios.post('lawork/case/deleteRelCaseClient/', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          // 리턴값으로 resolve 채우기
          resolve.isDeleted = data.isDeleted
          resolve.teamError = data.teamError
          resolve.chInfo = data.chInfo
          resolve.rsMsg = 'removed' // 메시지를 해제로 준다

          await this.initDialog() // 초기화
          this.resolve(resolve) // this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 취소 버튼 이벤트 핸들러 - 닫는 역할만 한다
    async cancel () {
      try {
        let resolve = {
          isDeleted: false, // 부모가 삭제되었는지 여부
          teamError: false, // 부모의 팀에러
          chInfo: null, // 부모의 에러에 대한 메시지
          rsMsg: 'success' // resolve 메시지
        }
        await this.initDialog() // 초기화
        this.resolve(resolve) // this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // --------------------------------------------------
    // 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>
